import { Switch, FormControlLabel, Box } from "@mui/material";
import { ReportsContext } from "../STATE";
import { useContext, useState, useEffect } from "react";
import { useAuth } from "../../../context/authContext/Auth";
import axios from "axios";
import API from "../../../api/Api";

function SwitchToCommissionReports() {
  const { config } = useAuth();

  const { dispatch, state, setParams } = useContext(ReportsContext);
  const [haveCommissionedReports, setHaveCommissionedReports] = useState(false);
  const [, setViewPurchaseTxt] = useState(false);

  const handleCommissionedReports = (e) => {
    setParams("isCommissionedReports", e.target.checked);
    dispatch({ type: "SET_PAGINATION", payload: { page: 1, limit: 10 } });
    dispatch({
      type: "SET_IS_COMMISSIONED_REPORTS",
      payload: e.target.checked,
    });
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchCommissionedReports = async () => {
      const url = `${API.Base_URL}/api/user-library-minor-details`;
      axios
        .get(url, { ...config, params: {
          is_commissioned_reports: true,
        },signal })
        .then((resp) => {
          if (resp.data.data) {
            const showCommissionedReports = resp.data.data.have_commissioned_reports;
            setHaveCommissionedReports(showCommissionedReports);
            setViewPurchaseTxt(!showCommissionedReports);
          }
        })
        .catch((err) => {
          if(["AbortError", "CanceledError"].includes(err.name)) return;
          console.log(err);
        });
    };

    fetchCommissionedReports();

    return () => {
      abortController.abort();
    };
  }, []);

  if (!haveCommissionedReports) return <></>;

  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0.8rem",
      }}
    >
      <FormControlLabel
        control={<Switch />}
        label="Commission Reports"
        checked={state.isCommissionedReports}
        onClick={handleCommissionedReports}
        disabled={!haveCommissionedReports}
      />
    </Box>
  );
}

export default SwitchToCommissionReports;
