import React, { useEffect } from 'react';
import './PowerBIEmbed.css'; // Import the CSS file
import API from "../../api/Api";

let models = window["powerbi-client"].models;


const PowerBIEmbed = () => {
  useEffect(() => {
    const getEmbedToken = async () => {
      try {
        const response = await fetch(`${API.Base_URL}/getEmbedToken`);
        if (!response.ok) {
          throw new Error('Failed to fetch embed token');
        }
        const embedData = await response.json();
        embedReport(embedData);
      } catch (error) {
        console.error('Error fetching embed token:', error);
      }
    };

    const embedReport = (embedData) => {
      const config = {
        type: 'report',
        tokenType: models.TokenType.Embed,
        accessToken: embedData.accessToken,
        embedUrl: embedData.embedUrl[0].embedUrl,
        settings: {
          panes: {
            filters: {
              expanded: true,
              visible: true
            }
          }
        }
      };

      const reportContainer = document.getElementById('reportContainer');
      if (reportContainer) {
        const report = window.powerbi.embed(reportContainer, config);

        report.on('loaded', () => {
          console.log('Report load successful');
        });

        report.on('rendered', () => {
          console.log('Report render successful');
        });

        report.on('error', (event) => {
          console.error('Embedding error:', event.detail);
        });
      }
    };

    getEmbedToken();
  }, []);

  return (
    <div>
      <header>
      </header>
      <div className="trial-banner">
      <div className="hide-trial-banner"></div>
      <div id="reportContainer" className="embed-container">
      </div>
      </div>
      <div className="error-container" id="errorContainer"></div>
    </div>
  );
};

export default PowerBIEmbed;
