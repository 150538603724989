import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Tabs, Tab, Paper, Button, Tooltip } from "@mui/material";
import backArrow from "../../../assets/back_arrow_blue.svg";
import mailImgWhite from "../../../assets/mail_icon_white.svg";
import backgroundImg from "../../../assets/new_drift.svg";
import downloadImg from "../../../assets/download_icon.svg";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HubsHomeChatDialog from "../../GlobalComponents/ContactUs";
import RenewDrawer from "../../Common/RenewDrawer";
import { Modal, Button as ButtonX } from "antd";
import { SET_ONDEMAND_CREDITS } from "../../Redux/action";
import axios from "axios";
import API from "../../../api/Api";
import { useAuth } from "../../../context/authContext/Auth";

function ReportLayout({
  children,
  setCurrentTab,
  currentTab,
  state,
  fetchData,
}) {
  const dispatch = useDispatch();
  const {
    selectedReportDetail,
    total_ondemand_credit,
    remaining_ondemand_credit,
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch({
      type: "SET_NAVBAR_DATA",
      payload: {
        heading: null,
        paragraph: null,
        bannerHeight: "464px",
      },
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.mainReportLayoutContainer}>
        <section className={styles.topSection}>
          <ReportBriefDetail
            state={state}
            remaining_ondemand_credit={remaining_ondemand_credit}
            fetchData={fetchData}
            selectedReportDetail={selectedReportDetail}
          />
        </section>
        <section className={styles.bottomSection}>
          <ReportTabs
            changeTab={setCurrentTab}
            tab={currentTab}
            subscribed={selectedReportDetail?.subscribed}
          />
          {children}
        </section>
      </div>
    </div>
  );
}

export default ReportLayout;

function formatDate(stringDate) {
  const date = new Date(stringDate);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = date.getUTCDate();
  const monthIndex = date.getUTCMonth();
  const year = date.getUTCFullYear();
  return `${day} ${months[monthIndex]}, ${year}`;
}

const ReportBriefDetail = ({
  state,
  remaining_ondemand_credit,
  fetchData,
  selectedReportDetail,
}) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");
  const [requestStatus, setRequestStatus] = useState("uninitialise");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState("no");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { Base_URL } = API;
  const { config } = useAuth();
  const handleBack = () => navigate(-1);

  useEffect(() => {
    setIsRequested(selectedReportDetail.is_requested_catalogue_report);
    setIsSubscribed(selectedReportDetail.subscribed);
  }, [selectedReportDetail]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setRequestStatus("uninitialise");
    setRequestMessage("");
  };

  const sendRequest = (event) => {
    event.preventDefault();
    setIsButtonDisabled(true);
    axios({
      url: `${Base_URL}/api/users/decrease-demand-credit`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: config.headers.authorization,
      },
      data: {
        report_id: state.report_id, //HERE
        message: requestMessage,
      },
    })
      .then((res) => {
        setRequestStatus("success");
        dispatch({
          type: SET_ONDEMAND_CREDITS,
          payload: {
            total_credit: res.data.data.credit.total,
            remaining_credit: res.data.data.credit.remaining,
          },
        });
        fetchData(); // HERE
      })
      .catch((error) => {
        setRequestStatus("failure");
        console.error(error);
      })
      .finally(() => {
        setIsButtonDisabled(false);
      });
  };

  let path;
  let pathToLink = [];
  let linkInformation = [];
  if (
    location?.pathname?.includes("hubs") &&
    location?.pathname?.includes("companies")
  ) {
    //Get the hub title from location pathname
    let hubSlug = location.pathname.split("/")[2];
    let companyNames = location.pathname.split("/")[4];
    let hubName = state?.hubTitle
      .split(" ")
      .map((str) => {
        return str[0].toUpperCase() + str.slice(1);
      })
      .join(" ");

    path = `My Hubs / ${
      hubName === "Cep" ? "CEP" : hubName
    }  / Companies Covered / ${state?.company?.name}`;

    linkInformation = [
      ...linkInformation,
      {
        link: `/hubs`,
        state: {
          id: state?.ids?.hubId,
        },
      },
      {
        link: `/hubs/${hubSlug}`,
        state: {
          id: state?.ids?.hubId,
        },
      },
      {
        link: `/hubs/${hubSlug}/companies`,
        state: {
          id: state?.ids?.hubId,
          isRedirectFromCompanyReports: true,
        },
      },
      {
        link: `/hubs/${hubSlug}/companies/${companyNames}`,
        state: {
          id: state?.ids?.hubId,
          isRedirectFromCompanyReports: true,
          hubSlug: hubSlug,
          hubId: state?.ids?.hubId,
          hubTitle: state?.hubTitle,
          company: state?.company,
          category: null,
        },
      },
    ];
    pathToLink.push("/hubs");
    pathToLink.push(`/hubs/${hubSlug}`);
    pathToLink.push("/companies");
    pathToLink.push(`/companies`);
  } else {
    let res = "";
    if (!state?.category) {
      res = "";
    } else if (state?.category && state?.category?.path) {
      res = state?.category?.path
        ?.split("/")
        ?.map((str) => {
          if (str != "") {
            return str[0].toUpperCase() + str.slice(1);
          } else {
            return "";
          }
        })
        .join(" / ")
        .split("-")
        ?.map((str) => {
          if (str != "") {
            return str[0].toUpperCase() + str.slice(1);
          } else {
            return "";
          }
        })
        .join(" ");
    }

    path = "Companies Covered " + res + ` / ${state?.company?.name}`;
  }
  let hubType = state?.hub_type
    .split(" ")
    ?.map((str) => {
      return str[0].toUpperCase() + str.slice(1).toLowerCase();
    })
    .join(" ");

  hubType = hubType === "Cep" ? "CEP" : hubType;

  const requestedTagStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 12px",
    borderRadius: "6px",
    backgroundColor: "rgba(0,255,0, .25)",
    color: "rgb(8, 71, 8)",
  };

  const getAccess = (isSubscribed, isRequested) => {
    if (isSubscribed === "yes") {
      return null;
    }

    if (isRequested) {
      return null;
    }

    return (
      <>
        <Tooltip title="Access This Report" placement="top">
          <button
            sx={{
              backgroundColor: "#189CDE",
              textTransform: "capitalize",
            }}
            onClick={() => {
              showModal();
            }}
            variant="contained"
          >
            Get Access
          </button>
        </Tooltip>
      </>
    );
  };

  const getPathLocation = () => {
    if (
      location?.pathname?.includes("hubs") &&
      location?.pathname?.includes("companies")
    ) {
      return `/hubs/${location?.pathname?.split("/")[2]}`;
    }
    return `/hubs/${hubType.toLowerCase()}`;
  };

  return (
    <section className={styles.details}>
      <div>
        <span>
          <Button
            onClick={handleBack}
            sx={{
              marginLeft: "-24px",
              marginRight: "0px",
              paddingRight: "0px",
              paddingLeft: "0px",
              width: "20px !important",
            }}
          >
            <img src={backArrow} alt="back" />
          </Button>
        </span>
        {location?.pathname?.includes("hubs") &&
        location?.pathname?.includes("companies") ? (
          path.split(" / ").map((str, index) => {
            return (
              <Link
                key={index}
                style={{
                  color: "rgb(90, 90, 90)",
                  textDecoration: "none",
                }}
                to={linkInformation[index]?.link}
                state={linkInformation[index]?.state}
              >
                {index === path.split(" / ")?.length - 1
                  ? str + " "
                  : str + " / "}
              </Link>
            );
          })
        ) : (
          <Link
            style={{
              color: "rgb(90, 90, 90)",
              textDecoration: "none",
            }}
            to="/companies"
          >
            {path}
          </Link>
        )}
      </div>
      <div>
        <h1>{state?.title?.toUpperCase() || ""}</h1>
        <div className={styles.purchaseDetails}>
          <p>
            {/* {state?.hasOwnProperty("published_year") &&
            state?.published_year != null ? (
              <>
                <span>Published In:</span> {formatDate(state?.published_year)}
                <span className={styles.dot}></span>
              </>
            ) : null} */}
            <Link
              to={getPathLocation()}
              state={{
                id: state?.ids?.hubId,
              }}
            >
              <span className={styles.hubName}>Hub: {hubType}</span>
            </Link>
          </p>

          <p>
            {state?.hasOwnProperty("purchased_on") &&
            state?.purchased_on != null ? (
              <>
                <span>Purchased On:</span> {formatDate(state.purchased_on)}
              </>
            ) : null}
            {(state?.hasOwnProperty("purchased_on") &&
              state?.purchased_on != null) ||
            (!state?.hasOwnProperty("renewal_on") &&
              state?.renewal_on != null) ? (
              <span className={styles.dot}></span>
            ) : null}

            {state?.hasOwnProperty("renewal_on") &&
            state?.renewal_on != null ? (
              <>
                <span>Renewal On:</span> {formatDate(state?.renewal_on)}
              </>
            ) : null}
          </p>
        </div>
        <div className={styles.studies}>
          <p>
            <span>Study Period:</span>{" "}
            {`${state?.study_period[0]} - ${state?.study_period[1]}`}
          </p>
          <p>
            <span>Regions Covered:</span> {state?.dimensions.region}
          </p>
        </div>
      </div>

      <div className={styles.btnContainer}>
        {getAccess(isSubscribed, isRequested)}

        <Tooltip title="Aak Your Query" placement="top">
          <button onClick={() => setDrawerOpen(true)}>
            <img src={mailImgWhite} alt="download" />
            Ask Your Query
          </button>
        </Tooltip>
      </div>

      <RenewDrawer
        title={"Ask Your Query"}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        typeModal={"contact"}
      />

      <Modal
        title={`${state?.title?.toUpperCase()}`} //HERE
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"800px"}
        styles={{
          width: "800px",
        }}
        footer={null}
      >
        <div className="request-modal">
          {requestStatus === "success" && (
            <div>
              <h1
                style={{
                  color: "#1BB1F0",
                  fontSize: "32px",
                  fontWeight: "bold",
                }}
              >
                Report Unlocked!
              </h1>
              <p className="description">Close this window to start reading</p>
              <p className="remaining-credits">
                Remaining Ondemand Credits :
                <span>{`${remaining_ondemand_credit}`}</span>
              </p>
            </div>
          )}
          {requestStatus === "uninitialise" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3
                style={{
                  marginBottom: "20px",
                  color: "rgb(125, 125, 125)",
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                Viewing this report will cost 1 credit.
              </h3>

              <p
                style={{
                  marginBottom: "20px",
                  color: "rgb(125, 125, 125)",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                {" "}
                Current Ondemand Credits :
                <span style={{ color: "#1BB1F0" }}>
                  {remaining_ondemand_credit}
                </span>
              </p>

              <ButtonX onClick={sendRequest} disabled={isButtonDisabled}>
                Request Access
              </ButtonX>
            </div>
          )}

          {requestStatus === "failure" && (
            <div>
              <h1 style={{ color: "rgba(255, 0, 0, 0.7)" }}>
                Something Went Wrong!
              </h1>
              <p className="description">Please try in some time.</p>
              <p className="remaining-credits">Your credits do not decrease.</p>
            </div>
          )}
        </div>
      </Modal>
      {/* <HubsHomeChatDialog open={isFormOpen} setOpen={setIsFormOpen}/> */}
    </section>
  );
};

const ReportTabs = ({ changeTab, tab, subscribed }) => {
  const handleChange = (event, newValue) => {
    changeTab(newValue);
  };

  return (
    <Paper className={styles.tabsSection} sx={{ zIndex: "10" }}>
      <Tabs
        value={tab}
        onChange={handleChange}
        centered
        TabIndicatorProps={{
          style: {
            background: "rgba(24, 156, 222, 1)",
          },
        }}
        sx={{
          "& .css-1wf8b0h-MuiTabs-flexContainer": {
            gap: "40px",
            "& > button": {
              textTransform: "none",
              font: "400 16px / 25.6px Avenir Roman",
            },
          },
          "& .css-1aquho2-MuiTabs-indicator": {
            color: "#1a76d2",
            borderColor: "#1a76d2",
            backgroundColor: "#1a76d2",
            height: "2px",
          },
        }}
      >
        <Tab sx={{ textTransform: "capitalize" }} label="Dashboard" />
        <Tab sx={{ textTransform: "capitalize" }} label="Snippet" />
        <Tab sx={{ textTransform: "capitalize" }} label="Company Profile" />
        <Tab
          sx={{ textTransform: "capitalize" }}
          label={`Report ${subscribed === "no" ? "Sample" : ""}`}
        />
        <Tab sx={{ textTransform: "capitalize" }} label="Data Sheet" />
        {/* <Tab label="Visualize" /> */}
      </Tabs>
    </Paper>
  );
};
