import { useEffect, useReducer, useRef } from "react";
import { initialState, reducer, PurchaseHistoryContext } from "./STATE";
import Layout from "./Layout";
import Filters from "./Filters";
import Pagination from "./Pagination";
import SynapseReportsCard from "../../HubsComponents/SynapseReportsCard";
import HubsReportCardForReports from "../../HubsComponents/HubsReportCardForReports";
import API from "../../../api/Api";
import axios from "axios";
import { useAuth } from "../../../context/authContext/Auth";
import Loader from "../../Common/Loader";
import { Typography, Box } from "@mui/material";

function PurchaseHistory() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const controllerRef = useRef(null);

  const { config } = useAuth();
  const { Base_URL } = API;

  async function getReportData() {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;

    dispatch({ type: "SET_LOADING", payload: "PENDING" });

    try {
      const response = await axios.get(
        `${Base_URL}/api/hubs/subscribed-reports`,
        {
          ...config,
          params: {
            is_library: state.isLibraryReports,
          },
          signal,
        }
      );

      if (response?.data.code === 200) {
        dispatch({ type: "SET_REPORTS", payload: response.data.data });
      }
    } catch (error) {
      console.error(error);
    } finally {
      // IF THE REQUEST IS ABORTED, THEN DO NOT DISPATCH THE LOADING STATE
      if (signal.aborted) return;
      dispatch({ type: "SET_LOADING", payload: "IDEAL" });
    }
  }

  useEffect(() => {
    getReportData();
  }, [state.isLibraryReports]);

  return (
    <PurchaseHistoryContext.Provider value={{ state, dispatch }}>
      <Layout>
        <Filters />
        {state.loading === "PENDING" ? (
          <Loader />
        ) : (
          state.reports.map((report, i) => {
            return (
              <CardToShow
                key={i}
                {...report}
                getReportData={getReportData}
                isLibraryReports={state.isLibraryReports}
              />
            );
          })
        )}
        {state.reports.length === 0 && state.loading === "IDEAL" && (
          <Box
            sx={{
              minHeight: "500px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              No {state.selectedTab.toLowerCase()}{" "}
              {state.isLibraryReports ? "Library" : ""} reports found
              {state.search ? (
                <span>
                  {" "}for search <strong>{state.search}</strong>
                </span>
              ) : (
                ""
              )}
              . <br /> Purchase some reports to view here.
            </Typography>
          </Box>
        )}
        <Pagination />
      </Layout>
    </PurchaseHistoryContext.Provider>
  );
}

export default PurchaseHistory;

const CardToShow = (props) => {
  if (props.isLibraryReports) {
    return <SynapseReportsCard {...props} currentTab={"PURCHASED_HISTORY"} />;
  }
  return (
    <HubsReportCardForReports {...props} currentTab={"PURCHASED_HISTORY"} />
  );
};
