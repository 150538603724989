import {
  Box,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useReportsContext } from "../../STATE";

function CatalogueReportStatus() {
  const { state, dispatch, setParams } = useReportsContext();

  const handleCatalogueReportStatusChange = (e) => {
    setParams("catalogueReportStatusFilter", e.target.value);
    dispatch({ type: "SET_PAGINATION", payload: { page: 1, limit: 10 } });
    dispatch({
      type: "SET_CATALOGUE_REPORT_STATUS_FILTER",
      payload: e.target.value,
    });
  };

  return (
    <Box sx={{ pl: 2 }}>
      <FormControl>
        <RadioGroup
          row
          value={state.catalogueReportStatusFilter}
          onChange={handleCatalogueReportStatusChange}
        >
          <FormControlLabel
            value="all_catalogue_reports"
            control={<Radio />}
            label="All Reports"
          />
          <FormControlLabel
            value="purchased_catalogue_reports"
            control={<Radio />}
            label="Purchased"
          />
          <FormControlLabel
            value="requested_catalogue_reports"
            control={<Radio />}
            label="Requested"
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

export default CatalogueReportStatus;
