/* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable react-hooks/exhaustive-deps */
import { initialState, reducer, ReportsContext, getReports } from "./STATE";
import { useReducer, useEffect, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ReportsLayout from "./ReportsLayout";
import NewSideBar from "../../components/Common/NewSideBar";
import MyReports from "./MyReports";
import Catalogue from "./Catalogue";
import ArchiveReports from "./ArchiveReports";
import SearchFilter from "./SearchFilter";
import Pagination from "./Pagination";
import { getTabBasedOnLibrary, onLoadStateChangeDueToParams, updateParamsOnManualChanges } from "./reports.utils";

function Reports() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [runLibEff, setRunLibEff] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  const setParams = (key, value) => setSearchParams((p) => { p.set(key, value); return p; });

  const fetchReports = useCallback(
    async (state) => {
      const path = searchParams.get("path") ?? "";
      await getReports(state, dispatch, { path });
    },
    [searchParams]
  );
  const tabFactory = {
    MY_REPORTS: state.isLibraryReports ? <ArchiveReports /> : <MyReports />,
    CATALOGUE: <Catalogue />,
    REPORTS_LIBRARY: <ArchiveReports />,
  };

  useEffect(() => {
    const tabParam = searchParams.get("tab");
    const tabTo = {
      MY_REPORTS: "MY_REPORTS",
      CATALOGUE: "CATALOGUE",
      REPORTS_LIBRARY: "REPORTS_LIBRARY",
    };
    dispatch({
      type: "CHANGE_TAB",
      payload: tabTo[tabParam] ?? "MY_REPORTS",
      fn: async (state) => {
        const updatedStateDueToOnLoadChanges = onLoadStateChangeDueToParams((p) => searchParams.get(p), dispatch, state);
        fetchReports(updatedStateDueToOnLoadChanges);
      },
    });
    setRunLibEff(true);
    return () => {}
  }, []);

  useEffect(() => {
    if (!runLibEff) return;
    updateParamsOnManualChanges(setSearchParams, state);
    dispatch({ type: `SET_${state.currentTab}_LOADING`, payload: "PENDING" });
    fetchReports(state);
  }, [state.isLibraryReports, state.isCommissionedReports, state.isLibraryHubReport, state.onlyHubReportsForMyReports, state.catalogueReportStatusFilter]);

  useEffect(() => {
    if (!state.sideBarCategory.slug) return;
    setSearchParams((p) => {
      p.set("path", `${state.sideBarCategory.slug}`);
      return p;
    });
  }, [state.sideBarCategory.slug]);

  const setCategoryItem = (id, item) => {
    if (state.sideBarCategory.currentCategory === id) return;
    dispatch({ type: "SET_LOADING", payload: "PENDING" });
    dispatch({ type: `SET_${getTabBasedOnLibrary(state.currentTab, state.isLibraryReports)}_LOADING`, payload: "PENDING" });
    const data = {
      currentCategory: id,
      path: item.parent === "/" ? "" : item.parent,
      title: item.title,
      fullPath: item.path,
      slug: item.slug,
      pathArray: item.path.split("/"),
    };
    dispatch({
      type: "SET_SIDEBAR_CATEGORY",
      payload: data,
      fn: async (state) => await getReports(state, dispatch),
    });
  };

  const handleSideBarReset = () => {
    dispatch({
      type: "SET_SIDEBAR_CATEGORY",
      payload: {
        currentCategory: null,
        path: "",
        title: "",
      },
    });
  };

  return (
    <ReportsContext.Provider value={{ state, dispatch, setParams }}>
      <ReportsLayout>
        <section>
          <NewSideBar
            selectedCategoryId={state.sideBarCategory.currentCategory}
            handleClose={handleSideBarReset}
            setCategoryItem={setCategoryItem}
            currentTab={state.currentTab}
            currentCategoryDetails={state.sideBarCategory}
          />
        </section>
        <section>
          <SearchFilter />
          {tabFactory[state.currentTab] ?? <h1>Lost</h1>}
          <Pagination />
        </section>
      </ReportsLayout>
    </ReportsContext.Provider>
  );
}

export default Reports;
