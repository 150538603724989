import {
  Box,
  Chip,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Popover,
  Button,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import exportFromJSON from "export-from-json";
import { useAuth } from "../../context/authContext/Auth";
import Loader from "../Common/Loader";
import CompaniesGraph from "./CompaniesGraph";
import { AES, enc } from "crypto-js";
import _ from "lodash";
import LockBoxNew from "../LockBox/LockBoxNew";
import { Modal, Switch, Button as ButtonX } from "antd";
import downloadIcon from "../../assets/download_icon.svg";
import FilterSidebarMarketShare from "../Common/FilterSidebarMarketShare";
import API from "../../api/Api";
import NoDataDisplay from "../Common/NoDataDisplay";
import { notification } from "antd";
import {
  checkAndUpdateDownloadCredit,
  displayNoDownloadCredit,
} from "../../utils/common";

import {
  setDownloadCredits,
  setCredits,
  SET_ONDEMAND_CREDITS,
} from "../Redux/action";
import Cookies from "universal-cookie";
import DownloadAccessChecker from "../Common/DownloadOptionCheck/DownloadAccessChecker";
import ShowComponentCheck from "../Common/ShowComponentCheck/ShowComponentCheck";

function CompanyMarketShare({ hubId, hubName, isSubscribed, reportId }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [unit, setUnit] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const cookies = new Cookies();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [initialData, setInitialData] = useState();
  const [initialData2, setInitialData2] = useState(); //for sample data

  const [obj2, setObj2] = useState([]); //for sample data
  const [region, setRegion] = useState("");
  const [region2, setRegion2] = useState(""); //for sample data
  const [reset, setReset] = useState(false);
  const [currReport, setCurrReport] = useState(null);
  const [dimensions, setDimensions] = useState({});
  const [dimensions2, setDimensions2] = useState({}); //for sample data
  const [selectedDimensions, setSelectedDimensions] = useState({});
  const [selectedDimensions2, setSelectedDimensions2] = useState({}); //for sample data
  const [filteredReports, setFilteredReports] = useState([]);
  const [checkDataHidden, setCheckDataHidden] = useState(false);
  const [checkRegionAll, setCheckRegionAll] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentTab, setCurrentTab] = useState("Graph View");
  const [reqHandler, setReqHandler] = useState({
    requestStatus: "uninitialise",
    isButtonDisabled: false,
    requestMessage: "",
  });
  const [domainInfo, setDomainInfo] = useState({});
  const { total_credit, remaining_credit, remaining_ondemand_credit } =
    useSelector((state) => state);
  const dispatch = useDispatch();
  const { config, userInfo } = useAuth();
  const { Base_URL } = API;

  const [hub, setHub] = useState("");
  const accountType = userInfo?.account_type;

  function formatNumberAbbreviation(paramNumber) {
    const value = Number(paramNumber);
    if (value >= 1e12) {
      return (value / 1e12).toFixed(2) + " T";
    } else if (value >= 1e9) {
      return (value / 1e9).toFixed(2) + " B";
    } else if (value >= 1e6) {
      return (value / 1e6).toFixed(2) + " M";
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(2) + " K";
    } else {
      return String(value.toFixed(2));
    }
  }

  const switchStyles = {
    marginLeft: "12px",
    color: "red",
    backgroundColor: "#446BA6",
    padding: "1px",
    height: "22px",
  };

  useEffect(() => {
    async function getInfo() {
      setLoading(true);

      axios
        .get(`${Base_URL}/api/hubs?category=&subCategory=`, config)
        .then((res) => {
          if (typeof res.data.data === "object") {
            setData(res.data?.data);
            setHub(res.data?.data[0].id);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (!hubId) {
      getInfo();
    }
  }, []);

  const getMarketShare = (id, filterper) => {
    setLoading(true);
    !filterper && setReset(true);
    axios
      .get(`${Base_URL}/api/company/marketShare?hubId=${id}`, config)
      .then((response) => {
        try {
          let item = response.data?.data;
          let bytes = AES.decrypt(item, "ZZ14YG>/X6/$%1yu1#0)0(E3]");
          const decrypted = bytes.toString(enc.Utf8);

          const mainData = JSON.parse(decrypted);
          setUnit(mainData?.unit);
          const res2 = mainData?.marketShare_data;

          setInitialData2(res2);
          // Check if "region" property is available in any item of res2
          const isRegionAvailable = res2.some(
            (item) => item.dimensions && item.dimensions.region === "All"
          );
          setCheckRegionAll(isRegionAvailable);

          if (filterper) {
            //
          } else {
            setRegion(isRegionAvailable ? "All" : "");
          }
          // Apply filter only if "region" is available
          const setObjtwo = isRegionAvailable
            ? res2.filter((item) => item.dimensions.region.includes("All"))
            : res2;
          setObj2(setObjtwo);
          setDomainInfo(res2?.[0]?.domainInfo);
        } catch (error) {
          console.log(error);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setReset(false);
      });
  };
  useEffect(() => {
    let id = hubId?.id ? hubId.id : hub;
    if (id) {
      getMarketShare(id);
    }
  }, [hub]);

  const regionData2 = [
    ...new Map(initialData2?.map((m) => [m.dimensions.region, m]))?.values(),
  ]; //for sample data

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setReqHandler((p) => {
      return { ...p, requestStatus: "uninitialise" };
    });

    setReqHandler((p) => {
      return { ...p, requestMessage: "" };
    });
  };

  const sendRequest = (event) => {
    event.preventDefault();
    setReqHandler((p) => {
      return { ...p, isButtonDisabled: true };
    });
    axios({
      url: `${Base_URL}/api/users/decrease-demand-credit`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: config.headers.authorization,
      },
      data: {
        report_id: filteredReports?.[0]?.report_id,
        message: reqHandler.requestMessage,
      },
    })
      .then((res) => {
        setReqHandler((p) => {
          return { ...p, requestStatus: "success" };
        });
        setCurrReport(filteredReports[0].report_id);
        dispatch({
          type: SET_ONDEMAND_CREDITS,
          payload: {
            total_credit: res.data.data.credit.total,
            remaining_credit: res.data.data.credit.remaining,
          },
        });
        let id = hubId?.id ? hubId.id : hub;
        if (id) {
          getMarketShare(id, true);
        }
      })
      .catch((error) => {
        setReqHandler((p) => {
          return { ...p, requestStatus: "failure" };
        });

        console.error(error);
      })
      .finally(() => {
        setReqHandler((p) => {
          return { ...p, isButtonDisabled: false };
        });
      });
  };

  const handleChange = (key) => {
    setRegion(key);
    setObj2(
      initialData2?.filter((item) => item.dimensions.region.includes(key))
    );
  };

  let SelectKeys2 = Object.keys(
    initialData2 !== undefined &&
      initialData2 !== null &&
      initialData2.length !== 0 &&
      initialData2[0]?.dimensions
  );

  const selectItems = [];
  const selectItems2 = [];

  for (let i = 0; i < SelectKeys2.length; i++) {
    if (initialData2 !== undefined) {
      let item = [
        ...new Map(
          initialData2?.map((m) => [m.dimensions[SelectKeys2[i]], m])
        )?.values(),
      ];
      let keyValue = item?.map((val) => val.dimensions[SelectKeys2[i]]);
      selectItems.push({ [SelectKeys2[i]]: keyValue });
    }
  }

  for (let i = 0; i < SelectKeys2.length; i++) {
    if (initialData2 !== undefined) {
      let item = [
        ...new Map(
          initialData2?.map((m) => [m?.dimensions[SelectKeys2[i]], m])
        ).values(),
      ];
      let keyValue = item.map((val) => val.dimensions[SelectKeys2[i]]);
      selectItems2.push({ [SelectKeys2[i]]: keyValue });
    }
  }

  const handleSelect = (event) => {
    setReset(true);
    setHub(event.target.value);
  };

  const handleToggle = (checked) => {
    setCurrentTab(checked ? "Table View" : "Graph View");
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const resetHandle = () => {
    const updatedData = {};
    // for (const key in selectedDimensions) {
    //   updatedData[key] = "All";
    // }

    setSelectedDimensions(updatedData);
    handleChange(checkRegionAll ? "All" : "");
  };

  const handleDimensionChange = (dimension, value) => {
    setSelectedDimensions((prevState) => ({
      ...prevState,
      [dimension]: value,
    }));
  };

  useEffect(() => {
    const newOne = obj2?.filter((report, index) => {
      let includeReport = true;
      Object.keys(selectedDimensions)?.forEach((dimension) => {
        if (report.dimensions[dimension] !== selectedDimensions[dimension]) {
          includeReport = false;
        }
      });
      // if (includeReport) {
      // }
      return includeReport;
    });

    if (currReport) {
      const sortedData = initialData2?.sort(compareDimensions);

      const crr = sortedData.filter((itm) => itm.report_id === currReport);

      setFilteredReports(crr);
      setCheckDataHidden(crr?.dataHidden ? true : false);
      setCurrReport(null);
    } else {
      const sortedData = newOne?.sort(compareDimensions);
      setFilteredReports(sortedData);
      setCheckDataHidden(sortedData?.[0]?.dataHidden ? true : false);
    }
  }, [selectedDimensions, obj2]);

  useEffect(() => {
    const newDimensions = {};
    obj2?.forEach((report) => {
      Object.keys(report.dimensions)?.forEach((dimension) => {
        if (!newDimensions[dimension]) {
          newDimensions[dimension] = new Set();
        }
        newDimensions[dimension].add(report.dimensions[dimension]);
      });
    });

    // Convert sets to arrays
    const dimensionsArr = {};
    Object.keys(newDimensions)?.forEach((dimension) => {
      dimensionsArr[dimension] = Array.from(newDimensions[dimension]);
    });

    //Sort arrays
    Object.keys(dimensionsArr)?.forEach((dimension) => {
      dimensionsArr[dimension].sort();
    });

    setDimensions(dimensionsArr);
  }, [obj2]);

  const handleRegionChange = (child) => {
    setSelectedDimensions({});

    handleChange(child);
  };

  function compileSubHeading(regionData, dimension) {
    let subHeading = "";
    if (region?.length > 0 && region !== "All") {
      subHeading = subHeading + `Region: ${region}`;
    }
    Object?.entries(dimension)?.map(([key, value], index) => {
      if (value !== "All") {
        subHeading =
          subHeading +
          `${subHeading?.length > 1 ? "," : ""} ${key.replace(
            /_/g,
            " "
          )}: ${value}`;
      }
    });
    //How to add comma between two strings

    return subHeading;
  }

  function compareDimensions(a, b) {
    // Extract the 'All' values from the 'dimensions' objects and sum them up
    const sumA = Object.values(a.dimensions).reduce(
      (total, value) => (value === "All" ? total + 1 : total),
      0
    );
    const sumB = Object.values(b.dimensions).reduce(
      (total, value) => (value === "All" ? total + 1 : total),
      0
    );

    // Compare the sums in descending order
    return sumB - sumA;
  }

  function GraphData(data) {
    const newData = [];
    data?.map((item) => {
      item?.data?.map((childItem) => {
        const data = {
          label: childItem.label,
          value: childItem.value,
          unit: unit,
        };

        return newData.push(data);
      });
    });
    let checkData = newData.sort((a, b) => b.value - a.value);

    // here we are filtring the same companeis
    const uniqueArr = checkData.reduce((acc, curr) => {
      const index = acc.findIndex((obj) => {
        const label1 = obj.label
          .replace(/,/g, "")
          .replace(/\./g, "")
          .toLowerCase();
        const label2 = curr.label
          .replace(/,/g, "")
          .replace(/\./g, "")
          .toLowerCase();
        return label1 === label2;
      });
      if (index === -1) {
        acc.push(curr);
      } else {
        acc[index].value += curr.value;
      }
      return acc;
    }, []);

    const val = uniqueArr.slice(0, 10).map((item, i) => ({ ...item }));

    const newVal = uniqueArr
      .slice(10, uniqueArr.length)
      .reduce((acc, obj) => acc + obj.value, 0);

    const total = val.reduce(
      (accumulator, currentValue) => accumulator + currentValue.value,
      0
    );

    if (total < 100 && total > 0) {
      return [...val];
    }
    if (newVal === 0) {
      return [...val];
    }
    return [...val];
  }
  function GraphData2(data) {
    return data[0]?.data.map((childItem) => {
      const data = {
        label: childItem.label,
        value: childItem.value,
        unit: unit,
      };
      return data;
    });
  }

  const formatExportData = (data, fn, fileName) => {
    let formattedDataSource = [];

    data.map((d) => {
      let o = {};
      o.Name = d.label;
      o[`Market Share (${d.unit})`] = `${fn(d.value, 2)}`;
      formattedDataSource.push(o);
    });

    for (let i = 0; i <= 2; i++) {
      let sourceObj = {};
      if (i === 1) {
        sourceObj.Name = `${fileName}`;
        sourceObj[`Market Share (${data[0].unit})`] = "";
      } else if (i === 2) {
        sourceObj.Name = "Source: Mordor Intelligence";
        sourceObj[`Market Share (${data[0].unit})`] = "";
      } else {
        sourceObj.Name = "";
        sourceObj[`Market Share (${data[0].unit})`] = "";
      }

      formattedDataSource.push(sourceObj);
    }

    return formattedDataSource;
  };

  const downloadButtonStyles = {
    border: "1px solid #446BA6",
    borderRadius: "14px",
    marginLeft: "12px",
    height: "22px",
    width: "60px",
  };

  const export_chart = async (format, id) => {
    if (total_credit != null) {
      if (remaining_credit <= 0) {
        displayNoDownloadCredit(total_credit);
        return;
      }
    }

    format = format.trim().toLowerCase();
    let selectedChart = FusionCharts("market-share-graph");
    if (selectedChart) {
      selectedChart?.exportChart({
        exportFormat: format,
      });
    }

    if (total_credit != null) {
      let credits = await checkAndUpdateDownloadCredit(config);

      dispatch(
        setDownloadCredits(credits.total_credit, credits.remaining_credit)
      );
    }
  };

  const exportAsTable = async (exportData, type) => {
    if (total_credit != null) {
      if (remaining_credit <= 0) {
        displayNoDownloadCredit(total_credit);
        return;
      }
    }

    let fileName =
      domainInfo?.domain + " Market Share - " + domainInfo?.company_market_year
        ? domainInfo?.company_market_year
        : domainInfo?.baseYear - 1;
    let exportType = exportFromJSON.types[type];
    let data = formatExportData(exportData, formatNumbers, fileName);

    exportFromJSON({ data, fileName, exportType });

    if (total_credit != null) {
      let credits = await checkAndUpdateDownloadCredit(config);

      dispatch(
        setDownloadCredits(credits.total_credit, credits.remaining_credit)
      );
    }
  };

  const handleDownloadModalOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDownloadModalClose = (event) => {
    setAnchorEl(null);
  };

  const formatToDecimals = (number, precision) => {
    return Number(number)?.toFixed(precision);
  };

  const formatNumbers = (number, precision) => {
    let x = Number(number)?.toFixed(precision);
    x = String(x);
    let arr = x.split(".");
    let y = arr[0];
    let z = arr[1];
    let pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(y)) y = y.replace(pattern, "$1,$2");

    if (z) {
      return String(y + "." + z);
    } else {
      return String(y);
    }
  };

  const Open = Boolean(anchorEl);
  const id = Open ? "popover" : undefined;

  const hasAccess = DownloadAccessChecker("hub", +cookies.get("hubId"));
  const toShow = ShowComponentCheck("market share");

  return (
    <>
      <Modal
        title={`${filteredReports?.[0]?.title?.toUpperCase()}`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"800px"}
        styles={{
          width: "800px",
        }}
        footer={null}
      >
        <div className="request-modal">
          {reqHandler.requestStatus === "success" && (
            <div>
              <h1
                style={{
                  color: "#1BB1F0",
                  fontSize: "32px",
                  fontWeight: "bold",
                }}
              >
                Thanks for the request!
              </h1>
              <p className="description">
                Our sales representative will be working on the same.
              </p>
              <p className="remaining-credits">
                Remaining Ondemand Credits :
                <span>{`${remaining_ondemand_credit}`}</span>
              </p>
            </div>
          )}
          {reqHandler.requestStatus === "uninitialise" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3
                style={{
                  marginBottom: "20px",
                  color: "rgb(125, 125, 125)",
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                Viewing this report will cost 1 credit.
              </h3>
              <p
                style={{
                  marginBottom: "20px",
                  color: "rgb(125, 125, 125)",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                {" "}
                Current Ondemand Credits :
                <span style={{ color: "#1BB1F0" }}>
                  {remaining_ondemand_credit}
                </span>
              </p>

              <ButtonX
                onClick={sendRequest}
                disabled={reqHandler.isButtonDisabled}
              >
                Get Access
              </ButtonX>
            </div>
          )}

          {reqHandler.requestStatus === "failure" && (
            <div>
              <h1 style={{ color: "rgba(255, 0, 0, 0.7)" }}>
                Something Went Wrong!
              </h1>
              <p className="description">Please try in some time.</p>
              <p className="remaining-credits">Your credits do not decrease.</p>
            </div>
          )}
        </div>
      </Modal>
      <Container style={{ position: "relative" }} maxWidth="xl">
        {loading ? (
          <Loader />
        ) : (
          <Grid container>
            {windowWidth >= 900 && (
              <Grid item md={3}>
                <FilterSidebarMarketShare
                  data={dimensions}
                  region={region}
                  resetAll={resetHandle}
                  handleDimensionChange={handleDimensionChange}
                  regionData={regionData2?.sort((a, b) => {
                    if (a.dimensions.region < b.dimensions.region) {
                      return -1;
                    }
                    if (a.dimensions.region > b.dimensions.region) {
                      return 1;
                    }
                    return 0;
                  })}
                  filteredReport={filteredReports?.[0]?.dimensions}
                  handleRegionChange={handleRegionChange}
                  recordState={selectedDimensions}
                />
              </Grid>
            )}
            <Grid item xs={11} md={9}>
              <Box
                component="div"
                id="graph-download-market-share-section"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                {GraphData2([...filteredReports])?.length > 1 && (
                  <Switch
                    onChange={handleToggle}
                    checkedChildren={"Table View"}
                    unCheckedChildren={"Graph View"}
                    style={switchStyles}
                  />
                )}
                {GraphData2([...filteredReports])?.length > 1 &&
                  !checkDataHidden &&
                  accountType !== "view only" && (
                    <Tooltip title={"View Export Options"} placement="top">
                      <Button
                        style={downloadButtonStyles}
                        onClick={handleDownloadModalOpen}
                      >
                        <img src={downloadIcon} alt="Download" />
                      </Button>
                    </Tooltip>
                  )}

                <Popover
                  id={id}
                  open={Open}
                  anchorEl={anchorEl}
                  onClose={handleDownloadModalClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  {currentTab === "Graph View" && (
                    <>
                      <p>
                        <Button
                          sx={{ width: "100%" }}
                          onClick={() => {
                            export_chart("jpg");
                          }}
                        >
                          Export As JPG
                        </Button>
                      </p>
                      <p>
                        <Button
                          sx={{ width: "100%" }}
                          onClick={() => {
                            export_chart("png");
                          }}
                        >
                          Export As PNG
                        </Button>
                      </p>
                      <p>
                        <Button
                          sx={{ width: "100%" }}
                          onClick={() => {
                            export_chart("svg");
                          }}
                        >
                          Export As SVG
                        </Button>
                      </p>
                      <p>
                        <Button
                          sx={{ width: "100%" }}
                          onClick={() => {
                            export_chart("pdf");
                          }}
                        >
                          Export As PDF
                        </Button>
                      </p>
                    </>
                  )}
                  {currentTab === "Table View" && (
                    <>
                      <p>
                        <Button
                          sx={{ width: "100%" }}
                          onClick={() =>
                            exportAsTable(
                              GraphData2([...filteredReports]),
                              "csv"
                            )
                          }
                        >
                          Export As CSV
                        </Button>
                      </p>
                      <p>
                        <Button
                          sx={{ width: "100%" }}
                          onClick={() =>
                            exportAsTable(
                              GraphData2([...filteredReports]),
                              "xls"
                            )
                          }
                        >
                          Export As XLS
                        </Button>
                      </p>
                    </>
                  )}
                </Popover>
              </Box>
              <Box>
                {region?.length > 0 && (
                  <Chip
                    sx={{
                      textTransform: "capitalize",
                      marginLeft: "1rem",
                    }}
                    label={`Region - ${region}`}
                  />
                )}

                {Object.entries(selectedDimensions).map(
                  ([key, value], index) => {
                    // if (  !== "All") {
                    return (
                      <Chip
                        key={index + Math.random()}
                        sx={{
                          textTransform: "capitalize",
                          marginLeft: "1rem",
                        }}
                        label={`${key.replace(/_/g, " ")} - ${value}`}
                      />
                    );
                    // } else {
                    //   return <></>;
                    // }
                  }
                )}

                {regionData2?.length < 1 ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80vh",
                    }}
                  >
                    <p>No Purchased Report</p>
                  </Box>
                ) : currentTab === "Graph View" ? (
                  <div className={checkDataHidden ? "blur" : ""}>
                    <CompaniesGraph
                      type="doughnut2d"
                      // regionData={regionData2}
                      // initData={obj2}
                      id={"market-share-graph"}
                      filterData={GraphData2([...filteredReports])}
                      // handleChange={handleChange}
                      // region={region}
                      // dimensions={dimensions}
                      setDimensions={setDimensions}
                      selectedDimensions={selectedDimensions}
                      // setSelectedDimensions={setSelectedDimensions}
                      resetHandle={resetHandle}
                      // handleDimensionChange={handleDimensionChange}
                      hubTitle={
                        domainInfo?.domain?.toLowerCase() +
                        ` Market Share - ${
                          domainInfo?.company_market_year
                            ? domainInfo?.company_market_year
                            : domainInfo?.baseYear - 1
                        }`
                      }
                      subTitle={filteredReports?.[0]?.title}
                      subHeading={compileSubHeading(
                        regionData2,
                        selectedDimensions
                      )}
                      filteredReports={filteredReports}
                    />
                  </div>
                ) : (
                  <div className={checkDataHidden ? "blur" : ""}>
                    {GraphData2([...filteredReports])?.length > 1 ? (
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell>
                                Market Share{" "}
                                {`(${
                                  GraphData2([...filteredReports])[0]?.unit ??
                                  ""
                                })`}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {GraphData2([...filteredReports])?.map(
                              (row, index, mainArray) => {
                                const { label, value } = row;
                                return (
                                  <TableRow key={label + value + Math.random}>
                                    <TableCell>{label}</TableCell>
                                    <TableCell>
                                      {/* {formatNumberAbbreviation(value)}{" "}
                                          {index === mainArray.length - 1
                                            ? mainArray[0]?.unit
                                            : row.unit} */}

                                      {formatNumbers(value, 2)}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <NoDataDisplay />
                    )}
                  </div>
                )}
              </Box>
            </Grid>
            {checkDataHidden && (
              <LockBoxNew
                onclick={() =>
                  remaining_ondemand_credit > 0 ? showModal() : false
                }
                sx={{
                  bottom: "5%",
                  left: "60%",
                  top: "unset",
                  zIndex: "5",
                  position: "absolute",
                }}
                componentName={"Market Share"}
                toShowText={!toShow ? "You don't have access for " : false}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "10px",
                    gap: "2px",
                    textTransform: "capitalize",
                  }}
                >
                  <h2>
                    {domainInfo?.domain?.toLowerCase() +
                      ` Market Share - ${
                        domainInfo?.company_market_year
                          ? domainInfo?.company_market_year
                          : domainInfo?.baseYear - 1
                      }`}
                  </h2>{" "}
                  <h4>{filteredReports?.[0]?.title}</h4>
                  <div>
                    {compileSubHeading(regionData2, selectedDimensions)}
                  </div>
                </div>
              </LockBoxNew>
            )}
          </Grid>
        )}
      </Container>
      {/* )} */}
    </>
  );
}

export default CompanyMarketShare;
