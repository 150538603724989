import { useReportsContext, getReports } from "../STATE";
import { memo } from "react";
import styles from "../styles.module.css";
import Loader from "../../../components/Common/Loader";
import HubsReportCardForReports from "../../../components/HubsComponents/HubsReportCardForReports";
import { Box } from "@mui/material";
import useDownloadBulkReports from "../SearchFilter/DownloadBulkReports";

function MyReports() {
  const { state, dispatch } = useReportsContext();

  const DownloadCheckBox = useDownloadBulkReports();

  const getReportsData = async () => {
    dispatch({ type: `SET_${state.currentTab}_LOADING`, payload: "PENDING" });
    await getReports(state, dispatch);
  };

  return (
    <div className={styles.myReportsMainContainer}>
      <section>
        {state.myReportsLoading === "PENDING" ? (
          <Loader />
        ) : state.myReports.length === 0 ? (
          <center>No Reports Found</center>
        ) : (
          state.myReports.map((report, i) => (
            <Box sx={{ position: "relative" }}>
              {/* <DownloadCheckBox id={report.mi_reports_id} /> */}
              <HubsReportCardForReports
                key={i}
                {...report}
                currentTab={state.currentTab}
                report_id={report.mi_reports_id}
                getReportData={getReportsData}
              />
            </Box>
          ))
        )}
      </section>
    </div>
  );
}

export default memo(MyReports);
