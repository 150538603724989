import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/authContext/Auth";
import { Provider } from "react-redux";
import store from "./components/Redux/store";
import TagManager from "react-gtm-module";
import { Worker } from '@react-pdf-viewer/core';

const tagManagerArgs = {
  gtmId: "GTM-5SWX6FNG", // Replace with your GTM container ID
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Provider store={store}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <App />
        </Worker>
        </Provider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode> 
);
