import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Stack,
  Typography,
  Popover,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState, useEffect } from "react";
import UserCard from "./UserCard";
import { useAuth } from "../../context/authContext/Auth";
import axios from "axios";
import Loader from "../Common/Loader";
import EmailModal from "./EmailModal";
import { Drawer, TextField, Select, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useDispatch } from "react-redux";
import API from "../../api/Api";
import { countryFlag } from "../../utils/contry/CountryCode";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { GoShieldCheck } from "react-icons/go";
import { BsChevronDown } from "react-icons/bs";
import { RiSearchLine } from "react-icons/ri";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: "24px 32px",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const customImage = {
  position: "absolute",
  left: 10, // Adjust the left position as needed
  top: "50%",
  transform: "translateY(-50%)",
  width: "95px", // Adjust the image width as needed
  display: "flex",
  gap: "10px",
  alignItems: "center",
  justifyContent: "center",
  borderRight: "1px solid #d5d5d5",
  paddingRight: "10px",
};

function UserManagement() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "SET_NAVBAR_DATA",
      payload: {
        heading: "Users",
        paragraph: null,
        backButtonData: null,
      },
    });
  }, []);
  const [data, setData] = useState([]);
  const [countriesList, setCountriesList] = useState(countryFlag);
  const [inputVal, setInputVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [cName, setCname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [formReady, setFormReady] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeAccount, setActiveAccount] = useState([]);

  const [isEdit, setIsEdit] = useState(false);
  const [flagCode, setFlagCode] = useState({
    flag: "india",
    country: "IND",
  });

  const { Base_URL } = API;
  const { setNotify, config, userInfo } = useAuth();

  const [errorText, setErrorText] = useState("");

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // const {  } = useAuth();

  const filterData = data?.alias_accounts?.filter((el) =>
    el?.name.toLowerCase().includes(inputVal.toLowerCase().trim())
  );
  async function getUsers() {
    setLoading(true);

    try {
      const response = await axios.get(`${Base_URL}/api/users`, config);

      setData(response?.data.data);
      const accounts = response?.data.data?.alias_accounts?.filter(
        (item) => item.active !== false
      );
      setActiveAccount(accounts);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    // Check if all required fields are filled in
    if (
      email &&
      username &&
      isValid &&
      role
      // &&
      // (isEdit || password)
    ) {
      setFormReady(true);
    } else {
      setFormReady(false);
    }
  }, [username, email, role, isValid, isEdit, password, cName]);

  const toggleDrawer = () => {
    setOpen(false);
    setIsEdit(false);
    setUsername("");
    setEmail("");
    setRole("");
    setPhoneNumber("");
    setCountryCode("+91");
    setCname("");
  };

  // const handlePhoneChange = (value) => {

  //   const formattedPhoneNumber = formatPhoneNumberIntl(value);
  //   // Extract the country code and phone number
  //   const inputArray = (formattedPhoneNumber || '').split(' ');
  //   const [firstValue, ...restValues] = inputArray;
  //   if (inputArray.length > 1) {
  //     setCountryCode(firstValue);
  //     setPhoneNumber(restValues.join(''))
  //   }

  // };

  // const handleEmailChange = (e) => {
  //   const inputValue = e.target.value;
  //   setEmail(inputValue);
  //   // Regular expression to check if the email is valid
  //   const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  //   setIsValid(emailPattern.test(inputValue));
  // };

  const handleEmailChange = (event) => {
    const domainName = userInfo.email.split("@")[1];
    const inputEmail = event.target.value;
    setEmail(inputEmail);

    // Basic email format validation
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isEmailValid = emailPattern.test(inputEmail);

    // Domain validation
    const inputDomain = inputEmail.split("@")[1];
    const isDomainValid = inputDomain === domainName;

    if (!isEmailValid) {
      setIsValid(false);
      setErrorText("Please enter a valid email address.");
    } else if (!isDomainValid) {
      setIsValid(false);
      setErrorText(`Email should belong to the ${domainName} domain.`);
    } else {
      setIsValid(true);
      setErrorText("");
    }
  };

  async function addUser() {
    // handleClose()
    const bodyParameters = {
      email: email,
      name: username,
      // mobile: {
      //   country_code: userInfo?.mobile.country_code,
      //   phone_number: userInfo?.mobile.phone_number,
      // },
      account_type: role.toLowerCase(),
      // admin_password: password,
      // company_name: cName,
    };

    try {
      const response = await axios.post(
        `${Base_URL}/api/users/add-alias`,
        bodyParameters,
        config
      );

      if (response?.data.code === 200) {
        handleOpen();
      }
    } catch (error) {
      console.error(error);
      setNotify({
        isOpen: true,
        message: error.response.data?.message || "Some Error occurred",
        type: "error",
        position: "right",
      });
    } finally {
      toggleDrawer();
    }
  }

  const submitEdit = async (e) => {
    e.preventDefault();
    const bodyParameters = {
      email: email,
      name: username,
      // mobile: {
      //   country_code: userInfo?.mobile.country_code,
      //   phone_number: userInfo?.mobile.phone_number,
      // },
      account_type: role.toLowerCase(),
    };

    try {
      const response = await axios.patch(
        `${Base_URL}/api/users/action-alias`,
        bodyParameters,
        config
      );

      //   setData(response?.data.data
      if (response?.data.code === 200) {
        setNotify({
          isOpen: true,
          message: response.data?.message,
          type: "success",
          position: "right",
        });
        getUsers();
        // toggleDrawer()
      }
    } catch (error) {
      console.error(error);
      setNotify({
        isOpen: true,
        message: error.response.data?.message || "Some Error occurred",
        type: "error",
        position: "right",
      });
    } finally {
      toggleDrawer();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addUser();
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    getUsers();
  };

  const handleOpenPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  const handleEditModal = (name, email, role, code, number) => {
    setOpen(true);
    setIsEdit(true);
    setUsername(name);
    setEmail(email);
    setRole(role);
    setPhoneNumber(number);
    setCountryCode(code);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box maxWidth={"1536px"} mx="auto">
      <Box maxWidth={"1536px"} m={6}>
        {/* <Typography mb={5} fontWeight={'bold'} mt={4} variant="h5">Users</Typography> */}
        {/* <EmailModal open={open} handleClose={handleClose} getUsers={getUsers} /> */}
        <Box display={"flex"} justifyContent={"space-between"} gap={"24px"}>
          <FormControl fullWidth>
            {/* <InputLabel htmlFor="outlined-adornment-amount">Search User</InputLabel>
            <OutlinedInput
              onChange={(e) => setInputVal(e.target.value)}
              value={inputVal}
              sx={{ height: '50px' }}
              id="outlined-adornment-amount"
              startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
              label="Search Users"
            /> */}
            <TextField
              // label="Search"
              sx={{
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderRadius: "8px",
                },
              }}
              placeholder="Search Users"
              variant="outlined"
              fullWidth
              onChange={(e) => setInputVal(e.target.value)}
              value={inputVal}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <RiSearchLine
                      style={{ fontSize: "24px", color: "#002F75" }}
                    />
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
            />
          </FormControl>
          <Button
            variant="contained"
            sx={{
              fontWeight: "bold",
              width: "200px",
              height: "50px",
              backgroundColor: "#189CDE",
              textTransform: "capitalize",
            }}
            onClick={() => setOpen(true)}
            startIcon={<AddIcon />}
          >
            Add New User
          </Button>
        </Box>
        <Box my={"48px"}>
          {activeAccount?.length >= 1 ? (
            <UserCard
              rows={filterData}
              data={data}
              getUsers={getUsers}
              handleEditModal={handleEditModal}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              You don't have any alias accounts.
            </Box>
          )}
        </Box>
        <Drawer anchor="right" open={open} onClose={toggleDrawer}>
          <div style={{ width: "444px", height: "88%", position: "relative" }}>
            <Stack
              p={"24px"}
              sx={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)" }}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "800",
                  lineHeight: "25.6px",
                  color: "#191919",
                }}
              >
                {isEdit ? "Edit User" : "Add New User"}
              </Typography>
              <IconButton onClick={toggleDrawer}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "20px",
                height: "100%",
              }}
            >
              <Box
                display={"flex"}
                flexDirection={isEdit ? "column-reverse" : "column"}
              >
                <Box>
                  <Typography
                    sx={{ my: "10px", fontWeight: "600", fontSize: "14px" }}
                  >
                    Name
                  </Typography>
                  <TextField
                    // label="Username"
                    autoComplete="off"
                    placeholder="Enter Name"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    fullWidth
                  />
                </Box>
                {/* <Box>
                  <Typography
                    sx={{ my: "10px", fontWeight: "600", fontSize: "14px" }}
                  >
                    Company Name
                  </Typography>
                  <TextField
                    // label="Username"
                    disabled
                    autoComplete="off"
                    placeholder="Enter Company Name"
                    name="username"
                    value={userInfo?.company_name}
                    onChange={(e) => setCname(e.target.value)}
                    fullWidth
                  />
                </Box> */}
                <Box>
                  <Typography
                    sx={{
                      mt: "20px",
                      mb: "7px",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Email
                  </Typography>
                  <TextField
                    autoComplete="off"
                    disabled={isEdit}
                    sx={{
                      background: isEdit ? "#E4E4E4" : "#fff",
                      color: isEdit ? "#878787" : "#000",
                    }}
                    variant="outlined"
                    placeholder="Enter Email"
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                    fullWidth
                    error={!isValid}
                    helperText={errorText}
                  />
                </Box>
              </Box>

              {/* <Box position="relative">
                <Typography
                  sx={{
                    mt: "20px",
                    mb: "7px",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  Phone
                </Typography>

                <Box sx={{ position: "relative", cursor: "pointer" }}>
                  <TextField
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Enter Phone Number"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "16.5px 14px 16.5px 115px",
                      },
                    }}
                  />
                  <Box style={customImage} onClick={handleOpenPopOver}>
                    <img
                      src={`https://s3.mordorintelligence.com/flags/${flagCode.flag.toLowerCase()}.svg`}
                      alt="country"
                      style={{ width: "22px", height: "16px" }}
                    />
                    <Typography sx={{ color: "#878787", fontWeight: "400" }}>
                      {flagCode.country.toUpperCase()}
                    </Typography>
                    <BsChevronDown
                      style={{ fontSize: "20px", color: "#002F75" }}
                    />
                  </Box>
                </Box>

                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleClosePopOver}
                  style={{ marginTop: "26px", marginLeft: "-5px" }}
                  // anchorReference="anchorPosition"
                  // anchorPosition={{ top: 410, left: 360 }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  sx={{ "& ::-webkit-scrollbar": { width: "0px" } }}
                >
                  <Box sx={{ width: "400px", height: "250px" }}>
                    <TextField
                      // label="Search"
                      sx={{
                        "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                          borderRadius: "8px",
                        },
                        padding: "16px",
                      }}
                      placeholder="Search"
                      variant="outlined"
                      fullWidth
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <RiSearchLine
                              style={{ fontSize: "24px", color: "#002F75" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box>
                      {countriesList
                        ?.filter((val) =>
                          val.country_name
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        )
                        .map((item) => (
                          <Button
                            fullWidth
                            key={item.calling_name}
                            onClick={() => {
                              setFlagCode({
                                flag: item.country_name,
                                country: item.country_code,
                              });
                              setCountryCode(`+${item.calling_code}`);
                              setInputValue("");
                              handleClosePopOver();
                            }}
                            sx={{
                              display: "flex",
                              gap: "16px",
                              padding: "16px 0px 16px 24px",
                              justifyContent: "start",
                              borderBottom: "1px solid #e4e4e4",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#2d2d2d",
                              textTransform: "capitalize",
                            }}
                          >
                            <img
                              src={`https://s3.mordorintelligence.com/flags/${item.country_name.toLowerCase()}.svg`}
                              alt="country"
                              style={{
                                width: "22px",
                                height: "16px",
                                borderRadius: "1px",
                                objectFit: "cover",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "2d2d2d",
                              }}
                            >
                              {item.country_name}
                            </Typography>
                          </Button>
                        ))}
                    </Box>
                  </Box>
                </Popover>
              </Box> */}
              <Typography
                sx={{
                  mt: "20px",
                  mb: "7px",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Permission
              </Typography>

              <FormControl fullWidth>
                {/* <InputLabel>User Role</InputLabel> */}
                <Select
                  // name="role"
                  displayEmpty
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <MenuItem sx={{ display: "none" }} value="" disabled>
                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#878787",
                      }}
                    >
                      Select Permission
                    </Typography>
                  </MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="view only">View Only</MenuItem>
                  <MenuItem value="view & download">View & download</MenuItem>
                </Select>
              </FormControl>
              {/* {!isEdit && (
                <>
                  <Typography
                    sx={{
                      mt: "20px",
                      mb: "7px",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Enter Admin Password
                  </Typography>
                  <TextField
                    // label="Password"
                    autoComplete="off"
                    placeholder="Enter Password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ background: "transparant" }}
                        >
                          <IconButton onClick={handleTogglePasswordVisibility}>
                            {showPassword ? (
                              <VisibilityOutlinedIcon />
                            ) : (
                              <VisibilityOffOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </>
              )} */}
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                }}
              >
                {/* <hr style={{
                  width: '100%',
                  position: 'absolute',
                  bottom: '9px',
                  right: '0px',
                  border: "none",
                  boxShadow: "0px -2px 8px rgba(0, 0, 0, 1)",
                  height: '1px',
                  marginBottom: "-10px"
                  // backgroundColor: "#333"
                }} /> */}
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={!formReady}
                  sx={{ textTransform: "capitalize" }}
                  // onClick={handleSubmit}
                  onClick={(e) => {
                    if (isEdit) {
                      submitEdit(e);
                    } else {
                      handleSubmit(e);
                    }
                  }}
                >
                  {isEdit ? "Save" : "Add User"}
                </Button>
              </Box>
            </form>
          </div>
        </Drawer>
      </Box>
      <Modal open={openModal} onClose={handleClose}>
        <Box
          sx={style}
          style={{ height: "376px", padding: "32px 32px 48px 32px" }}
        >
          <Box textAlign={"center"}>
            <GoShieldCheck
              style={{ width: "64px", height: "64px", color: "#189cde" }}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "800",
                lineHeight: "25px",
                color: "#191919",
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              User Added Successfully!
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "25px",
                color: "#5a5a5a",
                my: 2,
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              <span style={{ textTransform: "capitalize" }}>{username} </span>{" "}
              has been successfully added to the system. We've sent a
              verification link to the email address you provided. Once{" "}
              {userInfo?.relationship_manager?.[0]?.username} verifies the
              email, they'll be able to set a new password and access their
              account.
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "25px",
                color: "#5a5a5a",
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Need any further assistance? Feel free to reach out to our support
              team.
            </Typography>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default UserManagement;
