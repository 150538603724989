import axios from "axios";
import API from "../../api/Api";
import { Box } from "@mui/material";
// import Dashboard from "./Dashboard";
import AllGraphsDashboard from "./AllGraphsDashboard";
import ExecSummary from "./ExecSummary";
import Contents from "./Toc";

const fetchData = (reportData) => {
  const body = {};
  if (reportData.upload_id) {
    body['uploadId'] = reportData.upload_id;
    body['reportId'] = reportData.synapse_report_id;
  }
  return [
    axios.post(`${API.Base_URL}/api/library-reports-details/toc`, body),
    axios.post(`${API.Base_URL}/api/library-reports-details/snippet`, body),
    axios.post(`${API.Base_URL}/api/library-reports-details/dashboard-get-all-graphs-by-tags`, body),
    axios.post(`${API.Base_URL}/api/library-reports-details/graph-data`, body),
    axios.post(
      `${API.Base_URL}/api/library-reports-details/exec-summary`,
      body
    ),
  ];
};

export const getAllRequiredDetailsForReport = async (
  reportData,
  config,
  setTriggerApiCall,
  dispatch,
  setCurrentTab
) => {

  try {
    const promises = fetchData(reportData);
    const [toc, snippet, dashboard, listOfGraph, execSummary] =
      await Promise.all(promises);


    const tabArray = [];


   
    if (toc.data.isDataAvailable)
      tabArray.push({
        name: "Contents",
        component: <Contents data={toc.data.data.TOC} dispatch={dispatch} setCurrentTab={setCurrentTab}  />,
      });

    // if (snippet.data.isDataAvailable)
    //   tabArray.push({
    //     name: "Snippet",
    //     component: <Snippet data={snippet.data.data.snippets} />,
    //   });

    // if (dashboard.data.isDataAvailable)
    //   tabArray.push({
    //     name: "Dashboard",
    //     component: (
    //       <Dashboard
    //         data={dashboard.data.data.graphData}
    //         isSubscribed={reportData.subscribed === "yes"}
    //         title={reportData.title}
    //         id={reportData.synapse_report_id}
    //         compName={"Dashboard"}
    //         setTriggerApiCall={setTriggerApiCall}
    //       />
    //     ),
    //   });


    if (dashboard.data.isDataAvailable)
      tabArray.push({
        name: "Dashboard",
        component: (
          <AllGraphsDashboard
            data={dashboard.data.data}
            orderOfTags={dashboard.data.orderOfTags}
            isSubscribed={reportData.subscribed === "yes"}
            title={reportData.title}
            id={reportData.synapse_report_id}
            compName={"Dashboard"}
            setTriggerApiCall={setTriggerApiCall}
            dispatch={dispatch}
            setCurrentTab={setCurrentTab}
          />
        ),
      });

    if (execSummary.data.isDataAvailable)
      tabArray.push({
        name: "Executive Summary",
        component: (
          <ExecSummary
            data={execSummary.data.data.exec_summary}
            isSubscribed={reportData.subscribed === "yes"}
            title={reportData.title}
            id={reportData.synapse_report_id}
            setTriggerApiCall={setTriggerApiCall}
            snippetData={snippet.data.data.snippets}
            reportMeta={{
              title: reportData.title,
              publishedOn: reportData.published,
              forcastYear: reportData.forecast_year,
              websiteSlug: reportData.website_slug,
              countries:reportData.countries,          
              regions:reportData.regions,
              reportExpiry: reportData.report_expiry,
              subscribedOn: reportData.subscribed_on
            }}
          />
        ),
      });
 
    // if (listOfGraph.data.isDataAvailable)
    //   tabArray.push({
    //     name: "List of Graphs",
    //     component: (
    //       <Dashboard
    //         data={listOfGraph.data.data}
    //         isSubscribed={reportData.subscribed === "yes"}
    //         title={reportData.title}
    //         id={reportData.synapse_report_id}
    //         compName={"List of Graphs"}
    //         setTriggerApiCall={setTriggerApiCall}
    //       />
    //     ),
    //   });

    return tabArray;



  } catch (error) {
    console.error(error);
    return null;
  }
};

export const NoDataFoundForThis = () => {
  return (
    <Box
      sx={{
        minHeight: "500px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box component="center" sx={{ fontSize: "1rem" }}>
        No Data Found
      </Box>
    </Box>
  );
};
