import React, { useCallback, useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  Typography,
  Container,
  Button,
} from "@mui/material";
import Loader from "../Common/Loader";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import axios from "axios";
import { useAuth } from "../../context/authContext/Auth";
import API from "../../api/Api";
import { getReports, sideBarCategoryInitialState, useReportsContext } from "../../pages/Reports/STATE";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { getTabBasedOnLibrary } from "../../pages/Reports/reports.utils";

function RecursiveListItem({
  currentId,
  item,
  depth = 0,
  onIdClick,
  closeAllTabOnCurrentTab,
  currentCategoryPathDetails,
}) {
  const [open, setOpen] = useState(false);

  const closeAllOtherPaths = (pathSet) => {
    if (!pathSet) return;
    if (!pathSet.has(item.slug)) {
      return setOpen(false);
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const handleId = (value) => {
    onIdClick(value.id, value);
  };

  useEffect(() => {
    if (!currentId) {
      setOpen(false);
      return;
    }
    if (currentCategoryPathDetails.size !== 0) {
      closeAllOtherPaths(new Set(currentCategoryPathDetails));
      return;
    }
  }, [currentId]);

  useEffect(() => {
    setOpen(false);
  }, [closeAllTabOnCurrentTab]);
// 
  const listItemStyle = {
    paddingLeft: `${16 + depth * 16}px`,
    borderTop: depth === 0 ? `1px solid rgba(228, 228, 228, 1)` : "none",
    borderLeft:( open || currentCategoryPathDetails.has(item.slug))   ? "5px solid #189CDE" : "1px solid #D5D5D5",
    color: currentId === item.id ? "#189CDE" : "#000000",
    background: (currentId === item.id) && "#F0F7FC",
    marginLeft: depth === 0 ? `0px` : `${depth * 16}px`,
    width: depth === 0 ? `100%` : `calc(100% - ${depth * 16}px)`,
    position: "relative",
    left: "0px",
    cursor: "pointer",
  };

  const verticalLineStyle = {
    content: '""',
    position: "absolute",
    left: `${16 + depth * 16}px`,
    top: "0",
    bottom: "0",
    width: "1px",
    background: "#D5D5D5",
    zIndex: "-1",
    display: open && item.children.length > 0 ? "block" : "none",
  };

  return (
    <Box
      key={item._id}
      style={{ position: "relative", paddingLeft: "0px", paddingRight: "0px" }}
    >
      <ListItem style={listItemStyle} onClick={handleClick}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          <div
            onClick={() => handleId(item)}
            style={{ width: "100%", height: "100%" }}
          >
            <ListItemText
              primary={
                <>
                  <Typography>
                    {item.title}
                    {" "}
                    {item?.count && (
                      <Typography
                        variant="caption"
                        sx={{ color: "rgb(135, 135, 135)", fontSize: "14px" }}
                      >{`(${item.count}) Reports`}</Typography>
                    )}
                  </Typography>
                </>
              }
              sx={{
                fontWeight:
                  currentId == item.id ? "600 !important" : "400 !important",
                "& .MuiTypography-root": {
                  fontWeight:
                    currentId == item.id ? "600 !important" : "400 !important",
                },
                "& .css-10hburv-MuiTypography-root": {
                  fontWeight:
                    currentId == item.id ? "600 !important" : "400 !important",
                },
              }}
            />
          </div>
          {item.children.length > 0 && (
            <IconButton className={`icon-button`} onClick={handleClick}>
              {open ? (
                <ExpandLess style={{ color: "#189CDE" }} />
              ) : (
                <ExpandMore />
              )}
            </IconButton>
          )}
        </div>
      </ListItem>
      {item.children.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <div style={verticalLineStyle}></div>

            {item.children.map((child) => (
              <RecursiveListItem
                currentId={currentId}
                key={child._id}
                item={child}
                depth={depth + 1}
                onIdClick={onIdClick}
                currentCategoryPathDetails={currentCategoryPathDetails}
              />
            ))}
          </List>
        </Collapse>
      )}
    </Box>
  );
}

function NewSideBar({
  handleClose,
  setCategoryItem = () => {},
  selectedCategoryId,
  currentCategoryDetails,
}) {
  const { state: { currentTab, isLibraryHubReport, isLibraryReports, sideBarCategory }, dispatch, setParams } = useReportsContext();
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState("PENDING");
  const [, setCurrentId] = useState("");
  const { Base_URL } = API;
  const { config } = useAuth();

  const libStatus = {
    "hub_and_library_reports": "all",
    "only_library_reports": "library",
    "only_hub_reports": "hubs"
  };

  const fetchCategoryTree = useCallback(async (apiUrl, signal) => {
    try {
      setLoading("PENDING");
      const response = await axios.get(apiUrl, {
        ...config,
        params: {
          type: libStatus[isLibraryHubReport] || "all",
        },
        signal
      });
      setCategoryData(response.data.children);
    } catch (error) {
      if (["AbortError", "CanceledError"].includes(error.name)) return;
      console.log(error);
    } finally {
      if (signal.aborted) return;
      setLoading("IDLE");
    }
  }, [config, isLibraryHubReport]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const api = currentTab === "REPORTS_LIBRARY" ? `${Base_URL}/api/getLibraryCategory` : `${Base_URL}/api/hubs/getCategoryTree`;
    
    fetchCategoryTree(api, signal);

    return () => {
      console.log("Aborting fetchCategoryTree");
      controller.abort();
    };
  }, [currentTab, isLibraryHubReport]);

  const handleIdClick = (id, item) => {
    setCurrentId(id);
    setCategoryItem(id, item);
    window.scroll(0, 0);
  };

  const clearPathSearchParam = (tab) => {
    setParams("path", "");
    setParams("tab", tab);
    setParams("limit", "10");
    setParams("page", "1");
  }

  const resetCategory = () => {
    dispatch({ type: "SET_LOADING", payload: "PENDING" });
    dispatch({ type: `SET_${getTabBasedOnLibrary(currentTab, isLibraryReports)}_LOADING`, payload: "PENDING" });
    dispatch({
      type: "SET_SIDEBAR_CATEGORY",
      payload: sideBarCategoryInitialState,
      fn: async (state) => {
        clearPathSearchParam(state.currentTab);
        await getReports(state, dispatch);
      }
    })
  }

  return (
    <Container
      sx={{
        mb: "350px",
        mt: "6px",
        borderRadius: "6px",
        boxShadow:
          "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
        padding: "0px !important",
        paddingLeft: "0px !important",
        marginRight: "20px !important",
      }}
    >
      <Box my={6}>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            height: "60px",
            paddingInline: "18px",
            font: "800 16px / 25.6px Avenir Roman",
          }}
        >
          <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography fontWeight={600}>Categories</Typography>
          <Button
            onClick={() => {
              if(sideBarCategory.currentCategory === null) return;
              resetCategory();
              handleClose();
            }}
            sx={{
              textTransform: "none",
            }}
            endIcon={<RestartAltOutlinedIcon />}
          >Reset All</Button>
          </Box>
        </Box>
        {loading === "PENDING" ? (
          <Loader />
        ) : (
          <List component="nav" sx={{ padding: "0 !important" }}>
            {categoryData?.map((item) => (
              <RecursiveListItem
                currentId={selectedCategoryId}
                key={item._id}
                item={item}
                onIdClick={(id, item) => handleIdClick(id, item)}
                closeAllTabOnCurrentTab={currentTab}
                currentCategoryPathDetails={new Set(currentCategoryDetails.pathArray)}
              />
            ))}
          </List>
        )}
      </Box>
    </Container>
  );
}

export default NewSideBar;
