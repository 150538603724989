import React,{useState, useEffect} from 'react';
import styles from "./styles.module.css";
import { useSelector, useDispatch } from "react-redux";
import exportFromJSON from "export-from-json";
import { Box , Button, Tooltip, Popover} from "@mui/material";
import { Switch } from "antd";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import { useAuth } from "../../../context/authContext/Auth";
import downloadIcon from "../../../assets/download_icon.svg";
import Table from "../../../components/TemplateComponents/Table";
import ArchiveGraphs from '../../../Graphs/ArchiveGraphs';
import DownloadAccessChecker from "../../../components/Common/DownloadOptionCheck/DownloadAccessChecker";
import { checkAndUpdateDownloadCredit, displayNoDownloadCredit } from "../../../utils/common";
import { setDownloadCredits } from "../../../components/Redux/action";
import { colors } from "../../../utils/colors";
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
function View({
  data,
  index,
  alternateHeading,
  graphHeader,
  graphType,
  graphData,
  slide,
  reportId,
  graphTableId,
  positionIndex,
  tag,
  legends,
  dataHidden,
  blurred,
  isLibraryReport,
  gotToGraphInReport,
}) {
  const [currentView, setCurrentView] = useState("graph");
  const [currentGraph, setCurrentGraph] = useState("volume");
  const [displayData, setDisplayData] = useState({});
  const [dataType, setDataType] = useState("VOLUME");
  const [anchorEl, setAnchorEl] = useState(null);
  const [graphId, setGraphId] = useState(Math.ceil(Math.random()*1000) + "-" + graphHeader?.trim()?.toLowerCase());
  const hasAccess = DownloadAccessChecker("report", 12345);

  const dispatch = useDispatch();
  const { config, userInfo } = useAuth();
  const { total_credit, remaining_credit } = useSelector((state) => state);

  // useEffect(() => {
  //   let id = Math.ceil(Math.random()*1000) + "-" + graphHeader?.trim()?.toLowerCase();
  //   setGraphId(id) ;
  // }, [graphHeader]);

  const switchStyles = {
    marginLeft: "12px",
    color: "red",
    backgroundColor: "#446BA6",
    padding: "1px",
    height: "22px",
  };

  const downloadButtonStyles = {
    border: "1px solid #446BA6",
    borderRadius: "14px",
    marginLeft: "12px",
    height: "22px",
    width: "60px",
  };


  const COLORS = [...colors];
        COLORS.length = 10;


  let handleViewChange = (checked) => {
    setCurrentView(checked ? "table" : "graph");
  };

  let handleGraphChange = (checked) => {
    setCurrentGraph(checked ? "value" : "volume");
  };

  let handleDownloadModalOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  let handleDownloadModalClose = (event) => {
    setAnchorEl(null);
  };

  let formatExportData = (data, labels, fn, fileName) => {
    let formattedColumns = [];
    let formattedDataSource = [];
    switch (data.graphType) {
      case "bar":
      case "column":
      case "line": {
        for (let [index, value] of Object.keys(data.data[0]).entries()) {
          if (value === "value") {
            formattedColumns.push({
              title: "VALUE",
              key: value,
            });
          } else if (value === "label") {
            formattedColumns.push({
              title: "YEAR",
              key: value,
            });
          } else {
            formattedColumns.push({
              title: value.toUpperCase(),
              key: value,
            });
          }
        }

        for (let [index, value] of data.data.entries()) {
          let obj = {};

          for (let elem of formattedColumns) {
            let newKey = elem.title;
            obj[newKey] = value[elem.key];
          }
          formattedDataSource.push(obj);
        }

        break;
      }

      case "doughnut":
      case "pie": {

        let sum = data?.data.reduce((acc, cv)=>{
          return  acc + cv['value']
       }, 0)


        if (labels.length === 0) {
          for (let [index, value] of Object.keys(data.data[0]).entries()) {
            let newTitle = "";

            if (value === "label" && Number(data.data[0][value]) === NaN) {
              newTitle = "YEAR";
              formattedColumns.push({
                title: newTitle,
              });
            } else if (
              value === "label" &&
              Number(data.data[0][value]) !== NaN
            ) {
              newTitle = "COMPANIES";
              formattedColumns.push({
                title: newTitle,
              });
            } else if (
              value === "value" &&
              Number(data.data[0][value]) !== NaN
            ) {
              newTitle = data?.units
                ? "REVENUE " + `(${data.units})`
                : "REVENUE";
              formattedColumns.push({
                title: newTitle,
              });
            } else {
              newTitle = value.toUpperCase();
            }
          }
        } else {
          labels.map((label) => {
            let newTitle = "";
            if (label.toLowerCase() != "company") {
              newTitle = label + `(${data.units})`;
            } else {
              newTitle = label;
            }

            newTitle = newTitle.toUpperCase();
            formattedColumns.push({
              title: newTitle,
            });
          });
        }
        for (let [index, value] of data.data.entries()) {
          let obj = {};
          for (let elem of formattedColumns) {
            if (elem.title.includes("COMPAN")) {
              let key = elem.title;
              obj[key] = value["label"];
            } else {
              let key = elem.title;
              obj[key] =
                typeof value["value"] === "number" && !isNaN(value["value"])
                  ? value["value"] % 1 === 0
                    ? fn(value["value"]*(100/sum), 0) + " %"
                    : fn(value["value"]*(100/sum), 2) + " %"
                  : value["value"];
            }
          }

          formattedDataSource.push(obj);
        }

        break;
      }

      case "boxes": {
        for (let [index, value] of Object.keys(data.data[0]).entries()) {
          let newTitle = "";
          if (value === "label" && Number(data.data[0][value]) === NaN) {
            newTitle = "YEAR";
          } else if (value === "label" && Number(data.data[0][value]) !== NaN) {
            newTitle = "COMPANIES";
          } else if (value === "value" && Number(data.data[0][value]) !== NaN) {
            newTitle = "REVENUE";
          } else {
            newTitle = value.toUpperCase();
          }
          formattedColumns.push({
            title: newTitle,
            dataIndex: value.toLowerCase(),
            key: value.toLowerCase(),
          });
        }

        for (let [index, value] of data.data.entries()) {
          let obj = {
            key: `${index}`,
          };
          for (let elem of formattedColumns) {
            let newKey = elem.key;
            obj[newKey] =
              typeof value[newKey] === "number" && !isNaN(value[newKey])
                ? value[newKey] % 1 === 0
                  ? fn(value[newKey], 0)
                  : fn(value[newKey], 2)
                : value[newKey];
          }
          formattedDataSource.push(obj);
        }

        break;
      }

      case "Hstackedbar":
      case "stackedColumn": {
        for (let [index, value] of Object.keys(
          data.data.catagory[0]
        ).entries()) {
          let newTitle = "";
          if (
            value === "label" &&
            Number(data.data.catagory[0][value]) !== NaN
          ) {
            newTitle = "YEAR";
          } else {
            newTitle = value.toUpperCase();
          }
          formattedColumns.push({
            title: newTitle,
          });
        }
        for (let [index, value] of data.data.data.entries()) {
          let serName = value.seriesname
            .replaceAll("-", "")
            .replaceAll("<br/>", "");
          formattedColumns.push({
            title: serName.toUpperCase(),
          });
        }
        for (let [index, value] of data.data.catagory.entries()) {
          let genKey = Object.keys(value)[0];
          let obj = {
            ["YEAR"]: value[genKey],
          };
          formattedDataSource.push(obj);
        }
        for (let [i, v] of formattedDataSource.entries()) {
          let obj = v;
          for (let [ind, val] of data.data.data.entries()) {
            let serName = val.seriesname
              .replaceAll("-", "")
              .replaceAll("<br/>", "");
            obj[serName?.toUpperCase()] =
              typeof val.data[i]?.value === "number" &&
              !isNaN(val.data[i]?.value)
                ? val.data[i]?.value % 1 === 0
                  ? fn(val.data[i]?.value, 0)
                  : fn(val.data[i]?.value, 2)
                : val.data[i]?.value;
          }
        }
        break;
      }

      case "barline": {
        for (let [index, value] of Object.keys(data.data[0]).entries()) {
          let newTitle = "";
          if (value === "label" && Number(data.data[0][value]) !== NaN) {
            newTitle = "YEAR";
          } else if (value === "value") {
            newTitle = "YOY (%)";
          } else if (value === "volume") {
            newTitle = `${data.legends[0]} (${data.units})`.toUpperCase();
          } else {
            newTitle = value.toUpperCase();
          }
          formattedColumns.push({
            title: newTitle,
          });
        }

        for (let [index, value] of data.data.entries()) {
          let obj = {};
          for (let [i, v] of Object.keys(data.data[0]).entries()) {
            obj[formattedColumns[i].title] =
              typeof value[v] === "number" && !isNaN(value[v])
                ? value[v] % 1 === 0
                  ? fn(value[v], 0)
                  : fn(value[v], 2)
                : value[v];
          }

          formattedDataSource.push(obj);
        }
        break;
      }

      case "msline":
      case "groupedColumn": {
        let titleValue = ''
        for (let item of data?.data?.categories) {
          const  Year = Number(item.category[0].label.split("(")[0].trim());
          let  isYear =  Year != NaN && Year >= 2000 && Year <=2050
          
          titleValue = isYear === true ? "YEAR" : "LABEL";
          formattedColumns.push({
            title: titleValue
          });
        }

        for (let item of data?.data?.dataset) {
          formattedColumns.push({
            title: item.seriesname.toUpperCase(),
          });
        }

        for (let i = 0; i < data?.data?.categories[0].category?.length; i++) {
          formattedDataSource.push({
            [titleValue]: data?.data?.categories[0].category[i].label,
          });
        }

        for (let item of data?.data?.dataset) {
          for (let i = 0; i < item?.data?.length; i++) {
            let key = item?.seriesname.toUpperCase();
            formattedDataSource[i][key] = item?.data[i].value;
          }
        }
        break;
      }

      case "":
      case "none":
      case null:
      case undefined: {
        formattedColumns = null;
        formattedDataSource = null;
        break;
      }
      default: {
        formattedColumns = null;
        formattedDataSource = null;
      }
    }

    for (let i = 0; i <= 2; i++) {
      let sourceObj = {};
      formattedColumns?.map((column, index) => {
        let key = column?.title;
        let val = "";
        if (i === 1) {
          val = index === 0 ? `${fileName}` : "";
        } else if (i === 2) {
          val = index === 0 ? "Source: Mordor Intelligence" : "";
        } else {
          val = "";
        }
        sourceObj[`${key}`] = val;
      });
      formattedDataSource.push(sourceObj);
    }

   let  keys = Object.keys(formattedDataSource[0])

   let newObj ={};
     for(let i = 0; i < keys.length ; i++){
        let k = keys[i]
         newObj[k] = " ".repeat(i+1);
       }

   let newFormattedDataSource = formattedDataSource.map((item)=>{
       let newItem = {};
       for(let key in item){
        let newKey = newObj[key];
        newItem[newKey]  = item[key]
       }
       return  newItem;
    })

  if( data.graphType ===  "groupedColumn"){
     return formattedDataSource;
  }else{
    return newFormattedDataSource;
  }
  };

  let formatNumbers = (number, precision) => {
    let x = Number(number)?.toFixed(precision);
    x = String(x);
    let arr = x.split(".");
    let y = arr[0];
    let z = arr[1];
    let pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(y)) y = y.replace(pattern, "$1,$2");
    if (z) {
      return String(y + "." + z);
    } else {
      return String(y);
    }
  };

  let export_chart = async (format, id) => {
    if (total_credit != null) {
      if (remaining_credit <= 0) {
        displayNoDownloadCredit(total_credit);
        return;
      }
    }

    format = format.trim().toLowerCase();

    let selectedChart = FusionCharts(String(id));
    if (selectedChart) {
      selectedChart?.exportChart({
        exportFormat: format,
      });
    }

    if (total_credit != null) {
      let credits = await checkAndUpdateDownloadCredit(config);

      dispatch(
        setDownloadCredits(credits.total_credit, credits.remaining_credit)
      );
    }
  };

  let exportAsTable = async (exportData, labels, type) => {
    if (total_credit != null) {
      if (remaining_credit <= 0) {
        displayNoDownloadCredit(total_credit);
        return;
      }
    }

    let fileName = exportData.heading;
    let exportType = exportFromJSON.types[type];
    let data = formatExportData(exportData, labels, formatNumbers, fileName);

    exportFromJSON({ data, fileName, exportType });

    if (total_credit != null) {
      let credits = await checkAndUpdateDownloadCredit(config);

      dispatch(
        setDownloadCredits(credits.total_credit, credits.remaining_credit)
      );
    }
  };

  const Open = Boolean(anchorEl);
  const id = Open ? "popover" : undefined;

  const labels = displayData.hasOwnProperty("labels")
    ? displayData?.labels
    : [];

  let totalPlots = 0;
  let hideValueAndShowAxis = false;
  if (graphType === "groupedColumn") {
    totalPlots =
      graphData?.dataset?.length * graphData?.categories[0]?.category?.length;
    hideValueAndShowAxis = totalPlots <= 14 ? false : true;
  } else if (graphType === "column") {
    totalPlots = graphData?.length;
    hideValueAndShowAxis = totalPlots <= 8 ? false : true;
  } else if (graphType === "msline") {
    totalPlots = graphData?.categories[0]?.category?.length;
    hideValueAndShowAxis = totalPlots <= 14 ? false : true;
  }

  return (
    <Box
      sx={{
        gridColumn: "span 1",
        position: "relative",
      }}
    >
      {blurred && (
        <div
          style={{
            position: "absolute",
            top: "1px",
            zIndex: "2",
            margin: "auto",
            fontWeight: "bold",
            backgroundColor: "white",
            padding: "7px 0px 0px 0px",

            width: "100%",

            textTransform: "uppercase",
            fontSize: "15px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: "10px",
          }}
        >
        <div 
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent:"flex-end",
            }}>

          <Switch
            onChange={handleViewChange}
            checkedChildren="TABLE"
            unCheckedChildren="GRAPH"
            style={switchStyles}
          />
            <Tooltip 
              title={"Export Options Not Allowed"} 
              placement="top">
            <Button
              sx={{
                "&:hover":{
                  cursor:"not-allowed"
                }
              }}
              style={downloadButtonStyles}
              >
                <img src={downloadIcon} alt="Download" />
            </Button>
            </Tooltip>
          </div>
         
          <div
            style={{
              width: "100%",
              // color={colors[index]}
              color: "black",
              backgroundColor: COLORS[index % 10],
              padding: "10px",
            }}
          >
            {graphHeader}
          </div>
        </div>
      )}

      <Box
        sx={{
          boxSizing: "border-box",
          boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.1)",
          borderRadius: "8px",
          marginBottom: "2rem",
          overflow: "hidden",
          ...(blurred && {
            filter: "blur(5px)",
            pointerEvents: "none",
          }),
          "&:hover": {
            cursor: "pointer",
            boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.25)",
          },
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Switch
            onChange={handleViewChange}
            checkedChildren="TABLE"
            unCheckedChildren="GRAPH"
            style={switchStyles}
          />

          {hasAccess && (
            <>
            <Tooltip title={"View Export Options"} placement="top">
              <Button
                style={downloadButtonStyles}
                onClick={handleDownloadModalOpen}
              >
                <img src={downloadIcon} alt="Download" />
              </Button>
            </Tooltip>
            <Tooltip title={"Go to graph in report"} placement="top">
              <Button
                style={{...downloadButtonStyles, width:'fit-content'}}
                onClick={gotToGraphInReport}
              >
                <SummarizeOutlinedIcon fontSize='small' sx={{ color: "rgb(68, 107, 166)"}} />
              </Button>
            </Tooltip>
            </>
          )}

          <Popover
            id={id}
            open={Open}
            anchorEl={anchorEl}
            onClose={handleDownloadModalClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            {currentView === "graph" && (
              <>
                <p>
                  <Button
                    sx={{ width: "100%" }}
                    onClick={() => {
                      export_chart("jpg", graphId);
                    }}
                  >
                    Export As JPG
                  </Button>
                </p>
                <p>
                  <Button
                    sx={{ width: "100%" }}
                    onClick={() => {
                      export_chart("png", graphId);
                    }}
                  >
                    Export As PNG
                  </Button>
                </p>
                <p>
                  <Button
                    sx={{ width: "100%" }}
                    onClick={() => {
                      export_chart("svg", graphId);
                    }}
                  >
                    Export As SVG
                  </Button>
                </p>
                <p>
                  <Button
                    sx={{ width: "100%" }}
                    onClick={() => {
                      export_chart("pdf", graphId);
                    }}
                  >
                    Export As PDF
                  </Button>
                </p>
              </>
            )}
            {currentView === "table" && (
              <>
                <p>
                  <Button
                    sx={{ width: "100%" }}
                    onClick={() =>
                      exportAsTable(
                        {
                          graphType: graphType,
                          data: graphData,
                          heading: graphHeader,
                        },
                        labels,
                        "csv"
                      )
                    }
                  >
                    Export As CSV
                  </Button>
                </p>
                <p>
                  <Button
                    sx={{ width: "100%" }}
                    onClick={() =>
                      exportAsTable(
                        {
                          graphType: graphType,
                          data: graphData,
                          heading: graphHeader,
                        },
                        labels,
                        "xls"
                      )
                    }
                  >
                    Export As XLS
                  </Button>
                </p>
              </>
            )}
          </Popover>
        </Box>

        {currentView === "graph" ? (
          <ArchiveGraphs
            id={graphId}
            meta={displayData?.data?.meta}
            data={graphData}
            height={"425"}
            width={"100%"}
            header={graphHeader}
            footer={""}
            yAxisName={""}
            xAxisName={""}
            dynamiclegends={legends ? legends : []}
            graphType={graphType}
            dataHidden={dataHidden && dataHidden === true ? true : false}
            allow_cagr={
              displayData?.data?.hasOwnProperty("cagr_value") ? true : false
            }
            cagr_year={displayData?.data?.cagr_year}
            cagr_value={displayData?.data?.cagr_value}
            showValues={true}
            valueFontSize={12}
            valueFontBold={true}
            templateName={"Any"}
            slideName={"Any"}
            // color={COLORS[index]}
            headingColor={COLORS[index % 10]}
            hideValueAndShowAxis={hideValueAndShowAxis || false }
            isLibraryReport={isLibraryReport}
          />
        ) : (
          <Box
            sx={{
              height: "400px",
              paddingBottom: "0",
            }}
          >
            <Table
              tableData={{
                data: graphData,
                dataType: "",
                graphType: graphType,
                heading: graphHeader,
                units: "",
                url: "",
              }}
              labels={labels}
              parentTitle={graphHeader}
              heading={graphHeader}
              color={COLORS[index]}
              headingColor={COLORS[index % 10]}
              from={"report-dashboard"}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default View;