import Search from "./Search";
import Filter from "./Filter";
import Tags from "./Tags";
import styles from "../styles.module.css";
import { useContext } from "react";
import { ReportsContext } from "../STATE";
import { Box } from "@mui/material";
import SwitchToLibraryReports from "./SwitchToLibraryReports";
import SwitchToCommissionReports from "./SwitchToCommissionReports";
import SwitchToHubReports from "./SwitchHubOnlyReports";
import LibraryReportTypeChange from "./LibraryReportTypeChange";
import CatalogueReportStatus from "./Filter/CatalogueReportStatus";
import DownloadBulkBtn from "./DownloadBulkReports/DownloadBulkBtn";

function SearchFilter() {
  const { state } = useContext(ReportsContext);

  return (
    <div
      className={styles.mainSearchFilterContainer}
      id="report-search-section"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "4rem",
        }}
      >
        <FiltersToShow currentTab={state.currentTab} />
      </Box>
      <section className={styles.searchContainer}>
        <Search /> <Filter />
      </section>
      <section className={styles.tagContainer}>
        <Tags />
      </section>
    </div>
  );
}

export default SearchFilter;

const FiltersToShow = ({ currentTab }) => {
  switch (currentTab) {
    case "REPORTS_LIBRARY":
      return <LibraryReportTypeChange />;
    case "MY_REPORTS":
      return (
        <>
          <SwitchToLibraryReports />
          <SwitchToCommissionReports />
          <SwitchToHubReports />
          <DownloadBulkBtn />
        </>
      );
    case "CATALOGUE":
      return <CatalogueReportStatus />;
    default:
      return <></>;
  }
};
