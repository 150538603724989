import { memo, useEffect } from "react";
import { useReportsContext, getReports } from "../STATE";
import styles from "../styles.module.css";
import Loader from "../../../components/Common/Loader";
import HubsReportCardForReports from "../../../components/HubsComponents/HubsReportCardForReports";
import _ from "lodash";

function Catalogue() {
  const { state, dispatch } = useReportsContext();

  const getReportData = async () => {
    dispatch({ type: `SET_${state.currentTab}_LOADING`, payload: "PENDING" });
    await getReports(state, dispatch);
  };

  useEffect(() => {
    getReportData();
  }, []);

  return (
    <div className={styles.myReportsMainContainer}>
      <section>
        {state.catalogueLoading === "PENDING" ? (
          <Loader />
        ) : state.catalogueReports.length === 0 ? (
          <center>No Reports Found</center>
        ) : (
          state.catalogueReports.map((report, i) => (
            <HubsReportCardForReports
              key={_.uniqueId()}
              {...report}
              currentTab={state.currentTab}
              report_id={report.mi_reports_id}
              getReportData={() => getReportData(report.id)}
            />
          ))
        )}
      </section>
    </div>
  );
}

export default memo(Catalogue);
