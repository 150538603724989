import { useEffect } from "react";
import { useState } from "react";
import { NoDataFoundForThis } from "../fetchData";
import styles from "./styles.module.css";
import View from "../View";
import { Box, Button, Typography } from "@mui/material";
import LockBoxNew from "../../LockBox/LockBoxNew";
import { useSelector } from "react-redux";
import { ReportAccess } from "../Modals/ReportAccess";
import _ from "lodash";

export const correctGraphNames = {
  bar: "column",
  pie: "pie",
  line: "msline",
  stackedBar: "groupedColumn",
};

function AllGraphsDashboard({
  data,
  isSubscribed,
  id,
  title,
  compName,
  setTriggerApiCall,
  orderOfTags,
  dispatch,
  setCurrentTab: setCurrentTabFromParent,
}) {
  const { remaining_library_credit } = useSelector((state) => state);
  const [showModal, setShowModal] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [currentTab, setCurrentTab] = useState("");
  
  useEffect(() => {
    if (!data) return;
    setDashboardData(data);
    setTabs(orderOfTags);
    setCurrentTab(orderOfTags[0]);
  }, [data]);

  const handleCancel = () => {
    setShowModal(false);
    // setTriggerApiCall();
  };


  const validateData = (type, data)=>{
   if(type === 'pie' || type === 'bar' || type === 'column' || type === 'line' ){
    return data.every((item)=>{
       if(!item.hasOwnProperty('value') || !item.hasOwnProperty('label') ){
        return  false;
       }
       return  item.value !== 0 ? true : false
     })
   }
   return true;
  }

  if (!dashboardData) {
    return <NoDataFoundForThis />;
  }

  if (dashboardData?.length === 0) {
    return <div style={{
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      height:"540px"
    }}>
      <div className={styles.dashLoader}>
      </div>
    </div>;
  }

  const gotToGraphInReport = (slideNumber) => {
    if(!slideNumber) return;
    dispatch({
      type: "SET_CURRENT_LIBRARY_SLIDE",
      payload: slideNumber - 1,
    });
    setCurrentTabFromParent(3)
  }

  return (
    <div className={styles.container}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          marginBottom: "32px",
        }}
      >
        {tabs?.map((tab) => {
          if (tab == "null") {
            return null;
          }

          return (
            <Button
              key={tab}
              sx={{
                background: tab === currentTab ? "#446BA6" : "#FFFFFF",
                color: tab === currentTab ? "#FFFFFF" : "#446BA6",
                marginRight: "20px",
                "&:hover": {
                  color: tab === currentTab ? "#FFFFFF" : "#446BA6",
                  background: tab === currentTab ? "#446BA6" : "#FFFFFF",
                },
              }}
              onClick={() => {
                setCurrentTab(tab);
              }}
            >
              {tab.trim().toUpperCase()}
            </Button>
          );
        })}
      </Box>
      <div className={styles.grid}>
        {dashboardData[currentTab]?.map((item, index) => (
 
           validateData( correctGraphNames[item?.data?.graphType], item?.data?.fusion_chart_data)  === false 
           ? null
           :
           <Box>
           <View
              key={index}
              graphHeader={item?.data?.graphTitle}
              graphType={correctGraphNames[item?.data?.graphType]}
              graphData={item?.data?.fusion_chart_data}
              slide={item?.slide}
              reportId={item?.reportId}
              graphTableId={item?.graphTableId}
              positionIndex={item?.positionIndex}
              tag={item?.tag}
              index={index}
              blurred={!isSubscribed}
              isLibraryReport={true}
              gotToGraphInReport={() => gotToGraphInReport(item.slide)}
              />
            </Box>
        ))}
      </div>
      {!isSubscribed && (
        <LockBoxNew
          sx={{
            position: "relative",
            width: "fit-content",
          }}
          onclick={
            remaining_library_credit > 0 ? () => setShowModal(true) : false
          }
          componentName={compName}
        ></LockBoxNew>
      )}
      {showModal && (
        <ReportAccess 
        report_id={id} 
        title={title} 
        closeModal={handleCancel} 
        reloadFunction={setTriggerApiCall}
        />
      )}
    </div>
  );
}

export default AllGraphsDashboard;
