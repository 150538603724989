import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { Box, Grid } from "@mui/material";
import API from "../../api/Api";
import cagr1 from "../../assets/cagr1.svg";
import cagr2 from "../../assets/cagr2.svg";
import marketShare1 from "../../assets/marketShare1.svg";
import marketShare2 from "../../assets/marketShare2.svg";
import LockBox from "../../components/LockBox";
import Loader from "../../components/Common/Loader";
import View from "./View";

export const Dashboard = ({ reportId, isSubscribed }) => {

  const [loader, setLoader] = useState(false);
  const [normalObj, setNormalObj] = useState([]);
  const [valAndVol, setValAndVol] = useState([]);
  const [currView, setCurrView] = useState([]);
  const [metaData, setMetaData] = useState();

  const cookies = new Cookies();
  const userToken = cookies.get("TOKEN");
  const { Base_URL } = API;
  const { selectedReportDetail } = useSelector((state) => state);

  const IconObj = {
    0: marketShare1,
    1: marketShare2,
    2: cagr1,
    3: cagr2,
  };
  const colorData = [
    "#E0EFFA",
    "#D7F6EB",
    "#FFEDC7",
    "#E6E3FC",
    "#FCECEC",
    "#F7E6FF",
    "#a5cbe6",
    "#a5e6c6",
    "#ffe6a5",
    "#c6b1ff",
    "#ffc6c6",
    "#ffb1ff",
    "#b1ffec",
  ];
  let colorIndex = 0;

  useEffect(() => {
    let getDashboardData = async () => {
      setLoader(true);

      axios
        .get(`${Base_URL}/api/hubs/dashboard/complete?reportId=${reportId}`, {
          headers: { Authorization: `Bearer ${userToken}` },
        })
        .then((res) => {
          let data = res?.data?.data;
          setMetaData(data?.executiveSummaryData);

          const advanceData2 = [];
          setNormalObj([]);
          setValAndVol([]);
          computeGraphData(data?.marketSnapShotData);
          computeGraphData(data?.marketShareAnalysisData);

          data?.marketSegmentDataArray?.forEach((segmentItem) => {
            advanceData2.push(...computeGraphData(segmentItem, true));
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoader(false);
        });
    };

    if (reportId) {
      getDashboardData();
    }
  }, [reportId]);

  let computeGraphData = (data, isApply = false) => {
    const dataArray = [];
    data?.multitemplate_names?.map((item, index) => {
      let { config_data } = item;

      config_data?.map((childItem) => {
        const { dataType } = childItem?.data;
        if (
          childItem?.type === "graph" &&
          (dataType === "Volume" ||
            dataType === "Value" ||
            dataType === "value" ||
            dataType === "volume")
        ) {
          dataArray.push(childItem);
        }
      });
    });

    setCurrView((prev) => [
      ...prev,
      [...Array(dataArray.length).fill("Graph View")],
    ]);
    setNormalObj((prev) => [...prev, [...dataArray]]);
    setValAndVol((prev) => [
      ...prev,
      dataArray.length > 1 ? "Volume View" : "Value View",
    ]);
    return dataArray;
  };

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <Box
          sx={{
            width: "100%",
            maxWidth: "1710px",
            margin: "0 auto",
            padding: "0 15px",
          }}
        >
          <Box style={{ position: "relative", minHeight: "30vh" }}>
            {/* {!isSubscribed && (
              <LockBox
                sx={{
                  top: "50%",
                  left: "50%",
                  zIndex: "5",
                }}
                componentName={"Dashboard"}
                purchaseCustomText="Purchase the report to gain access."
              />
            )} */}
            <Box
            //  className={!isSubscribed ? "blur" : ""}
            >
              <h1 style={{ marginLeft: "1.4rem", marginTop: "1.5rem" }}>
                Executive Summary
              </h1>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "1.5rem",
                }}
              >
                {metaData?.hasOwnProperty("segment_data_blocks") &&
                metaData?.segment_data_blocks?.length
                  ? metaData?.segment_data_blocks?.map((data, index) => {
                      return (
                        <Box
                          key={index + Math.random()}
                          sx={{
                            flex: "0 0 23.5%",
                            display: "flex",
                            backgroundColor: colorData[index],
                            padding: "1.5rem",
                            boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.1)",
                            borderRadius: "8px",
                            justifyContent: "space-between",
                            "&:hover": {
                              cursor: "pointer",
                            },
                          }}
                        >
                          <div>
                            <p
                              style={{
                                fontSize: "32px",
                                color: "#191919",
                                // filter: !isSubscribed ? "blur(5px)" : "",
                              }}
                            >
                              {data.value}
                            </p>
                            <p style={{ fontSize: "14px", color: "#5A5A5A" }}>
                              {data.label}
                            </p>
                          </div>
                          <div>
                            <img src={IconObj[index]} alt="" />
                          </div>
                        </Box>
                      );
                    })
                  : null}

                {metaData?.hasOwnProperty("boxes") && metaData?.boxes?.length
                  ? metaData?.boxes?.map((data, index) => {
                      return (
                        <Box
                          key={index + Math.random()}
                          sx={{
                            flex: "0 0 23.5%",
                            display: "flex",
                            backgroundColor: colorData[index],
                            padding: "1.5rem",
                            boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.1)",
                            borderRadius: "8px",
                            justifyContent: "space-between",
                            "&:hover": {
                              cursor: "pointer",
                            },
                          }}
                        >
                          <div>
                            <p
                              style={{
                                fontSize: "30px",
                                color: "#191919",
                                filter: !isSubscribed ? "blur(5px)" : "",
                              }}
                            >
                              {data.value}
                            </p>
                            <p style={{ fontSize: "14px", color: "#5A5A5A" }}>
                              {data.label}
                            </p>
                          </div>
                          <div>
                            <img src={IconObj[index]} alt="" />
                          </div>
                        </Box>
                      );
                    })
                  : null}
              </Box>

              {normalObj?.length && normalObj[0]?.length ? (
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      padding: "20px 0",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {normalObj?.map((data, i) => {
                      if (data?.length === 0) {
                        return null;
                      } else {
                        return (
                         
                          <View
                            blurred={!isSubscribed}
                            alternateHeading={selectedReportDetail?.short_title}
                            data={data}
                            index={colorIndex++}
                            reportId={reportId}
                          />
                        
                        );
                      }
                    })}
                  </Box>
                </Box>
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    color: "rgb(125,125,125,1)",
                    margin: "20px auto",
                    marginTop: "0px",
                  }}
                >
                  No Executive Summary Present
                </p>
              )}
            </Box>
          </Box>
          <h1 style={{ marginLeft: "1.4rem", marginBottom: "1.5rem" }}>
            Key Findings
          </h1>

          {metaData ? (
            <Grid
              container
              sx={{
                marginLeft: "1.4rem",
                marginRight: "1.4rem",
              }}
            >
              {metaData?.blocks?.map((data, index) => {
                return (
                  <Grid item xs={12} md={5.95}>
                    <Box
                      sx={{
                        padding: "2.4rem",
                        boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.1)",
                        borderRadius: "8px",
                        marginBottom: "1.5rem",
                        marginRight: "1.5rem",
                        "&:hover": {
                          cursor: "pointer",
                          boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.2)",
                        },
                      }}
                      key={index + Math.random()}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            color: "#191919",
                            fontSize: "16px",
                            marginBottom: "0.8rem",
                            fontWeight: "bold",
                          }}
                        >
                          {data?.block}
                        </p>

                        {data?.icon ? (
                          <>
                            {data?.block?.toLowerCase().includes("player") ? (
                              <img
                                src={data?.icon}
                                alt={" "}
                                style={{
                                  maxHeight: "50px",
                                  maxWidth: "100px",
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  width: "80px",
                                  marginRight: "0.5rem",
                                  marginBottom: "2px",
                                }}
                              >
                                <img
                                  src={data?.icon}
                                  alt={" "}
                                  style={{
                                    objectFit: "contain",
                                    aspectRatio: "3/2",
                                    width: "100%",
                                  }}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <small
                            style={{
                              width: "36px",
                              height: "36px",
                              marginRight: "1.8rem",
                            }}
                          ></small>
                        )}
                      </div>

                      <hr
                        style={{
                          color: "#E0E0E0",
                          marginBottom: "0.7rem",
                          border: "1px solid #E0E0E0",
                        }}
                      />
                      <p
                        style={{
                          color: "#191919",
                          fontSize: "24px",
                          fontWeight: "bold",
                          filter: !isSubscribed?"blur(5px)":""
                            
                        }}
                      >
                        
                        {!data.hasOwnProperty("percentage")
                          ? data?.value
                          : isNaN(parseFloat(data?.percentage).toFixed(2))
                          ? isSubscribed === true
                            ? "00 %"
                            : "XX %"
                          : `${parseFloat(data?.percentage).toFixed(2)} %`}
                      </p>
                      <p
                        style={{
                          color: "#5A5A5A",
                          fontSize: "14px",
                          marginBottom: "0.8rem",
                        }}
                      >
                        {data.hasOwnProperty("label")
                          ? data?.label
                          : data?.segment}
                      </p>
                      <hr
                        style={{
                          color: "#E0E0E0",
                          marginBottom: "0.7rem",
                          border: "1px solid #E0E0E0",
                        }}
                      />
                      <p
                        style={{
                          color: "#5A5A5A",
                          fontSize: "16px",
                        }}
                      >
                        {data?.analysis}
                      </p>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <p
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "rgb(125,125,125,1)",
                margin: "20px auto",
                marginBottom: "30px",
              }}
            >
              No Key Findings Present
            </p>
          )}
        </Box>
      )}
    </div>
  );
};
