import { Switch, FormControlLabel, Box } from "@mui/material";
import { ReportsContext } from "../STATE";
import { useContext } from "react";

function SwitchHubOnlyReports() {

  const { dispatch, state, setParams } = useContext(ReportsContext);

  const handleHubOnlyReports = (e) => {
    setParams("onlyHubReportsForMyReports", e.target.checked);
    dispatch({ type: "SET_PAGINATION", payload: { page: 1, limit: 10 } });
    dispatch({
      type: "SET_ONLY_HUB_REPORTS_FOR_MY_REPORTS",
      payload: e.target.checked,
    });
  };


  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0.8rem",
      }}
    >
      <FormControlLabel
        control={<Switch />}
        label="Hub Reports"
        checked={state.onlyHubReportsForMyReports}
        onClick={handleHubOnlyReports}
      />
    </Box>
  );
}

export default SwitchHubOnlyReports;
