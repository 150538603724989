import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Container, CircularProgress, Typography } from "@mui/material";
import Cookies from "universal-cookie";
import { useAuth } from "../../context/authContext/Auth";
import API from "../../api/Api";
import LockBoxNew from "../LockBox/LockBoxNew";
import { useSelector } from "react-redux";
import { ReportAccess } from "../ArchiveReportDetails/Modals/ReportAccess";
const AnswerEngine = ({
  userEmail,
  hubId,
  libraryReportId = null,
  libraryReportPurchased = null,
  subscribed,
  fetchReport,
  title,
  id,
}) => {
  const { Base_URL } = API;
  const { config, notify, setNotify, userInfo } = useAuth();
  const cookies = new Cookies();
  const [loading, setLoading] = useState(false);
  const [showData, setShowData] = useState("");
  const iframeContainerRef = useRef(null);
  const { remaining_library_credit } = useSelector((state) => state);
  const [showModal, setShowModal] = useState(false);

  const handleCancel = () => {
    setShowModal(false);
    // fetchReport();
  };
  useEffect(() => {
    if (!userInfo) return;
    setLoading(true);
    const authenticate = async () => {
      try {
        const response = await axios.post(
          `${Base_URL}/api/hubs/authenticate-prodgain`,
          {
            user_email_id: userInfo.email,
            hub_id: +cookies.get("hubId"),
          },
          config
        );
        setShowData(response.data.uuid);
        setCookie("email", userInfo.email, 24);
        setCookie("authToken", response.data.uuid, 24);
        setCookie(
          "selectedHub",
          libraryReportId ? null : +cookies.get("hubId"),
          24
        );
        setCookie("reportId", libraryReportId ?? null, 24);

        if (libraryReportPurchased) {
          setCookie("reportSubscribed", libraryReportPurchased === "yes", 24);
        } else {
          setCookie("reportSubscribed", null, 24);
        }
        setLoading(false);

        // Handle successful response
      } catch (error) {
        console.error("Error during authentication:", error);
        setLoading(false);
        setShowData(error);
        // Handle error
      }
    };

    // Call the function when component mounts
    authenticate();
  }, [libraryReportId, userInfo, libraryReportPurchased]);

  const requiredDomain = {
    "synapse.mordorlabs.com": ".mordorlabs.com",
    "synapse.mordorintelligence.com": ".mordorintelligence.com",
    localhost: "",
  };

  const requiredIframeDomain = {
    "synapse.mordorlabs.com": "https://myra-chat.mordorlabs.com",
    "synapse.mordorintelligence.com":
      "https://myra-chat.mordorintelligence.com",
    localhost: "",
  };

  function setCookie(key, value, hours) {
    let date = new Date();
    date.setTime(date.getTime() + hours * 60 * 60 * 1000); // Set the expiration time in milliseconds
    let expires = "expires=" + date.toUTCString();
    const domainReq = requiredDomain[window.location.hostname];
    document.cookie =
      key + "=" + value + ";expires=" + expires + `;domain=${domainReq};path=/`;
  }

  let iFrameSrc = requiredIframeDomain[window.location.hostname];

  const handleIframeLoad = () => {
    if (iframeContainerRef.current) {
      iframeContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {!subscribed && id && (
        <>
          {" "}
          <LockBoxNew
            onclick={
              remaining_library_credit > 0 ? () => setShowModal(true) : false
            }
            componentName={"MyRA AI"}
          />
        </>
      )}
      {showModal && (
        <ReportAccess 
        report_id={id} 
        title={title} 
        closeModal={handleCancel}
        reloadFunction={fetchReport}
         />
      )}

      <Container
        ref={iframeContainerRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
          width: "90%",
          filter: id && !subscribed ? "blur(5px)" : "none",
          pointerEvents: id && !subscribed ? "none" : "unset",
        }}
      >
        {loading ? (
          <>
            <CircularProgress />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Authenticating...
            </Typography>
          </>
        ) : (
          // <Typography>{showData}</Typography>
          <>
            {/* <ChatUi /> */}
            <div
              style={{ height: "100vh", width: "100vw", margin: 0, padding: 0 }}
            >
              <iframe
                title="Answer Engine"
                style={{
                  height: "100%",
                  width: "100%",
                  border: "none",
                  margin: 0,
                  padding: 0,
                }}
                src={iFrameSrc}
                onLoad={handleIframeLoad}
              ></iframe>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default AnswerEngine;
