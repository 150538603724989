import { useState, useEffect } from "react";
import { NoDataFoundForThis } from "../fetchData";
import parse from "html-react-parser";
import styles from "./styles.module.css";

function Snippet({ data }) {
  const [snippetData, setSnippetData] = useState([]);

  useEffect(() => {
    if (!data) return;
    setSnippetData(data);
  }, [data]);
  if (!data) return <NoDataFoundForThis />;

  return (
    <div className="space-y-4 max-w-4xl">
      {snippetData.map((item, index) => (
        <div key={index} className={styles.infoContainer}>
          <h2 className={styles.heading}>{item.heading}</h2>
          <div className={styles.content}>{parse(item.content)}</div>
        </div>
      ))}
    </div>
  );
}

export default Snippet;
