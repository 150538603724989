import { FormControlLabel, Box, FormControl, RadioGroup, Radio } from "@mui/material";
import { ReportsContext } from "../STATE";
import { useContext } from "react";


function LibraryReportTypeChange() {


  const { dispatch, state, setParams } = useContext(ReportsContext);

  const handleHubReports = (e) => {
    setParams("isLibraryHubReport", e.target.value);
    dispatch({ type: "SET_PAGINATION", payload: { page: 1, limit: 10 } });
    dispatch({
      type: "SET_IS_LIBRARY_HUB_REPORTS",
      payload: e.target.value,
    });
  };

  return (
    <Box sx={{ pl: 2 }}>
      <FormControl>
        <RadioGroup
          row
          value={state.isLibraryHubReport}
          onChange={handleHubReports}
          sx={{ display: "flex", flexDirection: "row", gap: 2 }}
        >
          <FormControlLabel
            value="hub_and_library_reports"
            control={<Radio />}
            label="All Reports"
            defaultChecked
          />
          <FormControlLabel
            value="only_library_reports"
            control={<Radio />}
            label="Library"
          />
          <FormControlLabel
            value="only_hub_reports"
            control={<Radio />}
            label="Hub"
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

export default LibraryReportTypeChange;
